import ContentLink from "components/ContentLink/ContentLink";
import "./PrivacyPolicyPage.css";
import Page from "components/Page/Page";
import PolicyWarning from "components/Policies/PolicyWarning/PolicyWarning";
import PrivacyPolicy from "components/Policies/PrivacyPolicy/PrivacyPolicy";
import { ContactTicketSubmitter } from "components/forms/ContactForm/ContactTicketSubmitter";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicyPage() {
    const pageId = "privacy-policy-page";
    const navigate = useNavigate();

    const onContactClick = () => {
        ContactTicketSubmitter.navigateToContactWithPrefill(
            "Privacy Policy",
            null,
            () => { navigate("/contact?purpose=Support"); },
        );
    };

    const onRightToForgetClick = () => {
        ContactTicketSubmitter.navigateToContactWithPrefill(
            "Delete Personal Data Request",
            "Please delete the personal information associated with the email address I'm using to submit this " +
            "contact form. I affirm that I can reply to messages sent to this email, to prove that I own this account. " +
            "I understand that this will cause me to lose access to my account, and that no refunds for remaining play " +
            "tokens will be issued.",
            () => { navigate("/contact?purpose=Support"); },
        );
    };

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingText="Privacy Policy"
            hasCloseButton={true}
        >
            <div className="PrivacyPolicyPage">
                <h2 id="introduction">Introduction</h2>
                <p>Welcome to EndlessGPT. We are committed to protecting your personal
                    information and respecting your privacy. This Privacy Policy outlines
                    how we collect, use, and safeguard your information when you use our
                    AI-driven, text-based adventure game available worldwide.</p>
                <p>By accessing or using our service, you agree to the collection and
                    use of information in accordance with this policy.</p>
                <p>Your use of this service is subject to our <ContentLink text="Terms of Use" path="/terms-of-use" />.</p>
                <PolicyWarning />
                <hr />
                <PrivacyPolicy />
                <hr />
                <h2 id="contact-us">Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy,
                    please contact us using our email form on the <ContentLink text="Contact Us page" onClick={onContactClick} />. To exercise your "right to be forgotten" and request the deletion of your personal information, <ContentLink text="click here" onClick={onRightToForgetClick} />.</p>
                <p>Thank you for playing EndlessGPT. Your privacy is important to us,
                    and we are committed to protecting your personal information.</p>
            </div>
        </Page >
    );
}
