import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SwitchSelector, { OptionType } from "../SwitchSelector/SwitchSelector";
import "./ContactForm.css";
import TextInput from "../TextInput/TextInput";
import TextBox from "../TextBox/TextBox";
import SubmitButton, { SubmitButtonStates } from "components/forms/SubmitButton/SubmitButton";
import { useCaptchaModalStore } from "components/Captcha/CaptchaModal/CaptchaModalStore";
import { ErrorCopy } from "copy/ErrorCopy";
import FormMessages from "../FormMessages/FormMessages";
import { Validor } from "backend-models/app-api-models/validation/Validor";
import {
    ContactTicketSchema, IContactTicket, SubjectMaxChars, MessageMaxChars
} from "backend-models/services/contact/contact-api-models/IContactTicket";
import { IValidity } from "backend-models/app-api-models/validation/IValidity";
import { EmailMaxChars } from "backend-models/app-api-models/validation/CommonSchemas";
import { ContactCategory } from "backend-models/services/contact/contact-api-models/ContactCategory";
import { ContactTicketSubmitter } from "./ContactTicketSubmitter";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useSearchParams } from "react-router-dom";
import * as R from "ramda";
import { contactFormStore } from "./ContactFormStore";
import { InfoCopy } from "copy/InfoCopy";

export default function ContactForm() {
    const [searchParams] = useSearchParams();
    const { isAuthenticated, isLoading } = useAuth0();
    const { performCaptcha } = useCaptchaModalStore();
    const { subjectPrefill, messagePrefill, clearPrefills } = contactFormStore();

    const switchOptions: OptionType[] = [
        {
            label: "General Enquiry",
            value: "General Enquiry"
        },
        {
            label: "Support",
            value: "Support"
        },
        {
            label: "Report Content",
            value: "Report Content"
        },
        {
            label: "Business Enquiry",
            value: "Business Enquiry"
        },
    ];
    const purpose = searchParams.get("purpose") || "";
    const foundOptInd = purpose ? R.findIndex(o => o.value === purpose, switchOptions) : 0;
    const catStartInd = foundOptInd === -1 ? 0 : foundOptInd;
    const startCat = switchOptions[catStartInd].value;
    const [category, setCategory] = useState(startCat);
    const [initialSelectorIndex] = useState(catStartInd);

    const [email, setEmail] = useState("");
    const [initialEmail, setInitialEmail] = useState<string | undefined>(undefined);
    const [emailValidity, setEmailValidity] = useState<IValidity>();
    const [emailForceShowInvalid, setEmailForceShowInvalid] = useState(false);

    const [subject, setSubject] = useState("");
    const [initialSubject, setInitialSubject] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState("");
    const [initialMessage, setInitialMessage] = useState<string | undefined>(undefined);
    const subjectPrefillRef = useRef(subjectPrefill);
    const messagePrefillRef = useRef(messagePrefill);
    const clearPrefillsRef = useRef(clearPrefills);
    useEffect(() => {
        if (subjectPrefillRef.current) {
            setInitialSubject(subjectPrefillRef.current);
        }
        if (messagePrefillRef.current) {
            setInitialMessage(messagePrefillRef.current);
        }
        clearPrefillsRef.current();
    }, [subjectPrefillRef, messagePrefillRef, clearPrefillsRef]);

    const [subjectValidity, setSubjectValidity] = useState<IValidity>();
    const [subjectForceShowInvalid, setSubjectForceShowInvalid] = useState(false);
    const [messageValidity, setMessageValidity] = useState<IValidity>();
    const [messageForceShowInvalid, setMessageForceShowInvalid] = useState(false);
    const [submitButtonState, setSubmitButtonState] = useState(SubmitButtonStates.Ready);
    const [displayedReasons, setDisplayedReasons] = useState<string[]>([]);
    const [successMsg, setSuccessMsg] = useState("");

    const submit = async () => {

        const contactTicket: IContactTicket = {
            category: category as ContactCategory,
            email,
            subject,
            message,
        };
        const validity = Validor.getIsValid(ContactTicketSchema, contactTicket);

        if (validity.isValid) {

            setDisplayedReasons([]);
            setSubmitButtonState(SubmitButtonStates.Submitting);

            performCaptcha(

                // Captcha completed
                async (captchaToken: string) => {
                    console.log("Captcha completed");
                    try {
                        await ContactTicketSubmitter.submit(captchaToken, contactTicket);
                        setSubmitButtonState(SubmitButtonStates.Submitted);
                        setSuccessMsg(InfoCopy.ContactFormSubmittedSuccessfully);
                    }
                    catch (error) {
                        setSubmitButtonState(SubmitButtonStates.Error);
                        setDisplayedReasons([ErrorCopy.UnknownServerError]);
                    }
                },

                // Captcha cancelled
                () => {
                    setSubmitButtonState(SubmitButtonStates.Error);
                    setDisplayedReasons([ErrorCopy.CaptchaFailed]);
                    console.log("Aborted captcha");
                },
            );

        } else {
            setDisplayedReasons(validity.failureReasons);
            setEmailForceShowInvalid(!emailValidity?.isValid);
            setSubjectForceShowInvalid(!subjectValidity?.isValid);
            setMessageForceShowInvalid(!messageValidity?.isValid);
        }
    };

    const onEmailChange = (val: string, validity: IValidity) => {
        setEmail(val);
        setEmailValidity(validity);
        setEmailForceShowInvalid(false);
    };

    const onSubjectChange = (val: string, validity: IValidity) => {
        setSubject(val);
        setSubjectValidity(validity);
        setSubjectForceShowInvalid(false);
    };

    const onMessageChange = (val: string, validity: IValidity) => {
        setMessage(val);
        setMessageValidity(validity);
        setMessageForceShowInvalid(false);
    };

    useEffect(() => {
        const processLoginStatus = async () => {
            if ((!isLoading) && isAuthenticated) {
                try {
                    const profile = await UserProfileRetriever.getUserProfile();
                    setInitialEmail(profile.email);
                } catch (e) {
                    console.error("Failed to laod profile to pre-populate contact form");
                    console.error(e);
                }

            }
        };
        processLoginStatus();
    }, [isLoading, isAuthenticated]);

    return (
        <div className="ContactForm">
            <label>Purpose</label>
            <div className="ContactForm-switch">
                <SwitchSelector
                    onChange={(val) => setCategory(val as string)}
                    initialSelectedIndex={initialSelectorIndex}
                    wrapperBorderRadius={8}
                    optionBorderRadius={0}
                    selectionIndicatorMargin={0}
                    options={switchOptions}
                />
            </div>
            <label>Your email</label>
            <TextInput
                placeholderText="Email address"
                initialVal={initialEmail}
                maxLength={EmailMaxChars}
                onChanged={onEmailChange}
                validityTest={(email) => { return Validor.getIsValid(ContactTicketSchema.pick({ email: true }), { email }); }}
                forceShowInvalid={emailForceShowInvalid}
                onEnter={submit}
            />
            <label>Subject</label>
            <TextInput
                placeholderText="subject"
                initialVal={initialSubject}
                maxLength={SubjectMaxChars}
                onChanged={onSubjectChange}
                validityTest={(subject) => { return Validor.getIsValid(ContactTicketSchema.pick({ subject: true }), { subject }); }}
                forceShowInvalid={subjectForceShowInvalid}
                onEnter={submit}
            />
            <label>Message</label>
            <TextBox
                placeholderText="Your message"
                initialVal={initialMessage}
                maxLength={MessageMaxChars}
                onChanged={onMessageChange}
                validityTest={(message) => { return Validor.getIsValid(ContactTicketSchema.pick({ message: true }), { message }); }}
                forceShowInvalid={messageForceShowInvalid}
            />
            <div className="ContactForm-messages">
                <FormMessages failureReasons={displayedReasons} message={successMsg} />
            </div>
            <SubmitButton
                text="Submit"
                submittedText="Submitted"
                onPressed={submit}
                buttonState={submitButtonState}
            />
        </div>
    );
};
