/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from "react";
import "./ContentLink.css";

interface IProps {
    text: string;
    path?: string;
    onClick?: () => void;
}

export default function ContentLink(props: IProps) {

    return (
        <Fragment>
            {props.onClick &&
                <a className="ContentLink" onClick={props.onClick}>{props.text}</a>
            }
            {(!props.onClick) &&
                <a className="ContentLink" href={props.path}>{props.text}</a>
            }
        </Fragment>
    );
};
