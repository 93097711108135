import "./LoadableGame.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import LazyPicture from "components/LazyPicture/LazyPicture";
import { ChatViewType } from "components/Chat/ChatViewType";
import LoadButton, { LoadButtonStates } from "../LoadButton/LoadButton";
import { playStore } from "PlayStore";
import { LazyPicSize } from "components/LazyPicture/LazyPicSize";
import { JourneyInitializer } from "JourneyInitializer";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { GameSaver } from "components/Chat/ChatScene/GameSaver";
import * as R from "ramda";
import { useSaveCheckStore } from "components/SaveCheckModal/SaveCheckStore";
import { ISavedGameView } from "backend-models/services/saves/saves-api-models/ISavedGameView";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";

interface IProps {
    save: ISavedGameView,
}

export default function LoadableGame(props: IProps) {
    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    const { initialize } = playStore();
    const [loadButtonState, setLoadButtonState] = useState(LoadButtonStates.Ready);
    const { performSaveCheck } = useSaveCheckStore();

    const loadGame = async () => {
        performSaveCheck(

            // Proceed
            async () => {
                try {
                    setLoadButtonState(_ => LoadButtonStates.Loading);
                    const loadedJourney = await JourneyInitializer.loadJourney(props.save.turnLoc.turnId);
                    const latestTurn = R.last(loadedJourney.history) as ITurnView;
                    const autosaveTurnLoc: ITurnLocation = {
                        journeyId: loadedJourney.journeyId as string,
                        historyId: loadedJourney.currentHistoryId as string,
                        turnId: latestTurn.turnId as string,
                    };
                    await GameSaver.autosave(loadedJourney.game.id as string, autosaveTurnLoc);
                    initialize(loadedJourney);
                    setLoadButtonState(_ => LoadButtonStates.Ready);
                    navigate("/");
                } catch (e) {
                    setLoadButtonState(_ => LoadButtonStates.Error);
                    if (e instanceof AuthError) {
                        loginWithRedirect(
                            { appState: { returnTo: window.location.pathname } });
                    } else {
                        console.error(e);
                    }
                }
            },

            // Cancel
            () => { console.log("Aborted loading game"); },
        );
    };

    return (
        <div className="LoadableGame">
            <ChatBubble viewType={ChatViewType.Loadable} onBubbleClicked={loadGame} >
                <div className="LoadableGame-cols">
                    {props.save.picId &&
                        <div className="LoadableGame-pic-col">
                            <LazyPicture pictureId={props.save.picId} size={LazyPicSize.Small} />
                        </div>
                    }
                    <div className="LoadableGame-pre-col">
                        <ChatPreContainer>
                            <ChatPre viewType={ChatViewType.Loadable}>
                                <ChatText
                                    text={props.save.saveName}
                                    subText={props.save.savedTimeUtc.format("YYYY-MM-DD HH:mma")}
                                />
                            </ChatPre>
                        </ChatPreContainer>
                    </div>
                </div>
                <div className="LoadableGame-tray">
                    <LoadButton onPressed={loadGame} buttonState={loadButtonState} />
                </div>
            </ChatBubble>
        </div>
    );
};
