import ChatPre from "components/Chat/ChatPre/ChatPre";
import { ChatViewType } from "components/Chat/ChatViewType";
import HelpSection from "components/Help/HelpSection/HelpSection";
import { Fragment } from "react";
import Faq from "components/Help/Faq/Faq";
import PolicyWarning from "components/Policies/PolicyWarning/PolicyWarning";
import ContentLink from "components/ContentLink/ContentLink";

export default function GameManual() {

    return (
        <Fragment>
            <HelpSection>
                <p>Forget everything you know about how to play games.</p>
                <p>EndlessGPT™ is a new genre. It's a world simulator.</p>
                <img src="3d_globe_small.webp" alt="A world inside a snowglobe" />
                <p>In an RPG, every activity makes you richer or stronger. In an adventure game, every location has a purpose in your quest, and everything the game lets you do has a part to play in solving the puzzle.</p>
                <p>In EndlessGPT, there is an entire world full of stuff. You might find a mysterious observatory, break in and find a bright comet in the sky, but unless you have a good reason to be there, your only reward might be getting thrown in the dungeons for breaking and entering. Just because you can go somewhere, doesn't mean it's somewhere important. Sure, you might find something useful, but that doesn't mean it's relevant to your quest.</p>
                <p>Instead of asking yourself, "what am I meant to do here", ask, <i>"what would I do if I was really here?"</i></p>
                <p>You need to forge your own path. Use your detective skills. Come up with realistic solutions and expect only realistic results from your actions.</p>
                <p>That said, if breaking into observatories with your friends is your thing, knock yourself out! You are free to ignore the main quest, explore and have fun!</p>
                <p>But if you **** around, you will definitely find out.</p>
            </HelpSection>
            <HelpSection>
                <PolicyWarning />
                <p><ContentLink text="Privacy Policy" path="/privacy-policy" /> | <ContentLink text="Terms of Use" path="/terms-of-use" /></p>
            </HelpSection>
            <HelpSection>
                <h2>How To Play</h2>
                <img src="help/how_to_play.webp" alt="Illustrated Title saying 'How to play'" />
                <p>Specify an action for your character to perform.</p>
                <p>That's really it, as long you stick to describing your own actions or dialog, and your action is reasonable.</p>
                <p>Also note that you can't necessarily interact with what you see in the images, only the text. The images are just there to add atmosphere.</p>
                <p>You can use simple actions, like:</p>
                <ul className="HelpSection-actions">
                    <li>look around</li>
                    <li>enter shop</li>
                    <li>open chest</li>
                    <li>take sword</li>
                    <li>attack</li>
                    <li>follow path</li>
                    <li>buy it</li>
                </ul>
                <p>Or more complex ones, like:</p>
                <ul className="HelpSection-actions">
                    <li>I flip a gold coin to the pixie, saying, "Thank you for the information, little one!"</li>
                    <li>I use my lightning ring to electrify my longsword and swing for the troll's leg</li>
                </ul>
                <h3>Talking</h3>
                <p>The best way to talk is to provide the actual words you want to say. This allows you to express yourself best.</p>
                <p className="HelpSection-actions">Say, "howdy there, how do you do?"</p>
                <p>A shortcut for this is to start the action with a single quote. This indicates that the rest of the action is dialog for your character to speak, e.g:</p>
                <p className="HelpSection-actions">'howdy there, how do you do?</p>
                <p>However, if there are many people nearby, it's best to specify who you're talking to, e.g:</p>
                <ul className="HelpSection-actions">
                    <li>I say to the gnome, "Howdy there, how do you do?"</li>
                    <li>'Howdy there, how do you do, Mr. gnome?</li>
                    <li>I pat Jasper on the back, saying, "Good job!"</li>
                </ul>
                <h3>Special actions</h3>
                <img className="HelpSection-small" src="help/special_actions.webp" alt="A fantasy compass representing special actions" />
                <table><tbody>
                    <tr><td><ChatPre viewType={ChatViewType.Example}>look around</ChatPre></td><td>Take in your surroundings.<br />&nbsp;<br />Use this often, especially when you arrive in a new location. Otherwise you'll be playing with 'tunnel vision'.</td></tr>
                    <tr><td><ul className="HelpSection-actions"><li>examine x</li><li>investigate x</li></ul></td><td>Take a closer look at something, e.g:<ul className="HelpSection-actions"><li>examine loose brick</li><li>investigate waterfall</li></ul></td></tr>
                    <tr><td><ul className="HelpSection-actions"><li>items</li><li>check inventory</li></ul></td><td>See what equipment and possessions you have on you. (0 tokens)</td></tr>
                    <tr><td><ul className="HelpSection-actions"><li>purse</li><li>check money</li></ul></td><td>See how much money you have (0 tokens)</td></tr>
                    <tr><td><ul className="HelpSection-actions"><li>skills</li><li>check abilities</li></ul></td><td>List your special skills, spells or abilities (0 tokens)</td></tr>
                    <tr><td><ChatPre viewType={ChatViewType.Example}>recall x</ChatPre></td><td>Access your character's memories to see what you know about something, e.g:<ul className="HelpSection-actions"><li>recall what types of magic I could major in</li><li>recall what I know about the student with the magnifying glass</li></ul></td></tr>
                </tbody></table>
            </HelpSection>
            <HelpSection>
                <h3>Interface</h3>
                <ul className="HelpSection-chat-controls">
                    <li><i className="fas fa-save"></i>Create a saved game at this turn</li>
                    <li><i className="fa-solid fa-backward"></i>Rewind the game to this turn</li>
                </ul>
                <p>The game will auto-save as you play, but make sure you save before you rewind or start a new journey, in case want to come back to your current place of progress.</p>
            </HelpSection>
            <HelpSection>
                <h2>Tips for the best experience</h2>
                <p>EndlessGPT is generally pretty good at keeping things on rails, but to a certain extent you get out what you put it. EndlessGPT thrives on good roleplay from you.</p>
                <p>If traveling is feeling slow ("how many turns will it take to get to there?"), consider trying something like <span className="HelpSection-actions">i go all the way to the end of the path</span>, or <span className="HelpSection-actions">i follow all the way to the destination</span>. If it's a long way, you still might not be able to go there in one turn, but you might find something worth investigating along the journey! Also, there are improved means of travel to be found, such as mounts, ships, waygates, etc. It's even possible to achieve flight, if you play your cards right!</p>
                <p>While there <i>is</i> a main quest and endgame content, it isn't shoved down your throat. You can just play the game like a life simulator if you want to. You do start with a place in life, but you could go out and make new place for yourself, anywhere in the world!</p>
            </HelpSection>
            <h2>F.A.Q.</h2>
            <Faq />
        </Fragment>
    );
};
