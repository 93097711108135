import FaqAnswer from "components/Help/FaqAnswer/FaqAnswer";

export default function DaydreamAnswer() {

    return (
        <FaqAnswer>
            <p>This is what happens when you do something which EndlessGPT considers impossible. If you think your action should be allowed, here are some things you can try.</p>
            <p>Break it down into smaller actions:</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>go to weapon shop</td><td>go to market district<br />&nbsp;<br />look for a weapon shop</td></tr>
                <tr><td>i learn a fireball spell</td><td>look for a chapter in the fire magic book about shooting fireballs<br />&nbsp;<br />i read the chapter and try practicing the spell</td></tr>
                <tr><td>i climb the ladder, go to the ledge above the troll and then jump down and do a plunging attack</td><td>climb ladder<br />&nbsp;<br />sneak to the place on the ledge directly above the troll<br />&nbsp;<br />leap off and plunge attack the troll</td></tr>
            </tbody></table>
            <p>Start your action with "i":</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>we pick up the crate and shift it</td><td>i grip the crate and ask Jasper to help shift it</td></tr>
                <tr><td>push him off the edge</td><td>i push him off the edge</td></tr>
            </tbody></table>
            <p>Be very specific about how you achieve what you are trying to do:</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>I summon a direwolf</td><td>I read the incancation in the direwolf summoning scroll</td></tr>
                <tr><td>I calm the angry bear</td><td>I sing soothingly to the bear, using my Time Speedup Spell to make the bear feel like its bed time</td></tr>
            </tbody></table>
            <p>Only specify an action for the player character to perform:</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>everyone goes to the docks</td><td>lead the way to the docks</td></tr>
                <tr><td>Fang attacks the troll</td><td>I command Fang to attack the troll</td></tr>
                <tr><td>My party rows harder</td><td>shout encouragingly, 'Everyone row as hard as you can!'</td></tr>
                <tr><td>Make Torival tell me what he knows</td><td>say menacingly, 'Tell me what you know, Torival, or I'll hand you over to the city guard'</td></tr>
            </tbody></table>
            <p>Reword your action (sometimes EndlessGPT can get hung up on a particular word or phrase):</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>take him to the police</td><td>take him to the city guard</td></tr>
                <tr><td>empty stardust powder into cauldron</td><td>i add my powder</td></tr>
            </tbody></table>
            <p>Avoid dictating outcomes:</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>convince Mojo to join me</td><td>i try to convince Mojo to join me</td></tr>
                <tr><td>i run him through</td><td>thrust with my sword</td></tr>
                <tr><td>i kill the goblin</td><td>i attack the goblin mercilessly</td></tr>
                <tr><td>unlock the door</td><td>attempt to pick the lock</td></tr>
            </tbody></table>
            <p>Provide actual dialog instead of describing what you say:</p>
            <table className="HelpSection-actions"><tbody>
                <tr><th>Instead of</th><th>Try</th></tr>
                <tr><td>i tell him about the toys in the cabin</td><td>'We found enchanted toys in the rogue wizard's cabin</td></tr>
            </tbody></table>
            <p>There may also be something about your environment which you haven't noticed, preventing the action, such as insufficient space, or some other effect. Try looking around, or simply entering: <span className="HelpSection-actions">i try to figure out why i can't x</span></p>
        </FaqAnswer>
    );
};
