import "./ChatPanel.css";
import ChatScene from "components/Chat/ChatScene/ChatScene";
import { IJourneyView } from "backend-models/services/journies/journies-api-models/IJourneyView";
import { OpeningResolver } from "./OpeningResolver";
import ChatInput from "components/Chat/ChatInput/ChatInput";
import { Fragment, useEffect, useState } from "react";
import { scroller, animateScroll as scroll } from "react-scroll";
import * as R from "ramda";
import ChatAction from "components/Chat/ChatAction/ChatAction";
import delay from "delay-async";
import * as uuid from "uuid";
import { useAuth0 } from "@auth0/auth0-react";
import { authStore } from "auth/AuthStore";
import { ITakeTurnRequest } from "backend-models/app-api-models/ITakeTurnRequest";
import { playStore } from "PlayStore";
import { IGameInProgressView } from "backend-models/services/games/games-api-models/IGameInProgressView";
import { useSaveCheckStore } from "components/SaveCheckModal/SaveCheckStore";
import { GameSaver } from "components/Chat/ChatScene/GameSaver";
import { AuthError } from "auth/AuthError";
import HistoryLoader from "components/Chat/HistoryLoader/HistoryLoader";
import { HttpErrorMessage } from "backend-models/app-api-models/HttpErrorMessage";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import { ChatViewType } from "components/Chat/ChatViewType";
import ChatText from "components/Chat/ChatText/ChatText";
import { usePlaySetupStore } from "components/Chat/PlaySetupStore";
import { ProblemType } from "backend-models/services/engine/histories/histories-api-models/ProblemType";
import { IActionView } from "backend-models/services/journies/journies-api-models/IActionView";
import { ISceneView } from "backend-models/services/journies/journies-api-models/ISceneView";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";
import { IBeginJourneyRequest } from "backend-models/app-api-models/IBeginJourneyRequest";
import { IBeginJourneyResult } from "backend-models/app-api-models/IBeginJourneyResult";
import { ITakeTurnResult } from "backend-models/app-api-models/ITakeTurnResult";

interface IProps {
  pageId: string;
}

export default function ChatPanel(props: IProps) {
  const decoder = new TextDecoder();
  const termChar = "║";

  const scrollDelayMs = 500;
  const fakeErrorIdPrefix = "unkerr-";
  const latestActionElemId = "latest-action";

  const { loginWithRedirect } = useAuth0();
  const { accessToken, setTokenBalance } = authStore();
  const { setPreservedActionText } = usePlaySetupStore();
  const {
    initJourney,

    game,
    history,
    latestTurn,
    latestAction,
    latestScene,
    journeyId,
    currHistoryId,
    newScrollId,

    setHistory,
    setLatestAction,
    setLatestScene,
    setJourneyId,
    setCurrHistoryId,
    clearNewScrollId,
    setLatestTurn,
    revertHistory,
  } = playStore();

  const { performSaveCheck } = useSaveCheckStore();

  const [isAwaitingScene, setIsAwaitingScene] = useState(false);
  const [latestSceneOverrideText, setLatestSceneOverrideText] = useState<string | null>(null);
  const [latestSceneSecondaryText, setLatestSceneSecondaryText] = useState<string | undefined>(undefined);
  const [isBetweenSubmittingAndFocusing, setIsBetweenSubmittingAndFocusing] = useState(false);

  const initGame = game as IGameInProgressView;
  const gameId = (initGame.builtInGameId || initGame.id) as string;
  const openingView = OpeningResolver.resolve(initGame, history);

  const onFocusInput = () => {
    setIsBetweenSubmittingAndFocusing(false);
    setTimeout(scrollToEnd, scrollDelayMs);
  };

  const scrollToEnd = () => {
    scroll.scrollToBottom({
      containerId: props.pageId,
      duration: 10,
      delay: 10,
      smooth: "linear",
    });
  };

  const getUnkErrorTurn = (actionText: string, isFirstTurn: boolean) => {
    const unkErrorAction: IActionView = {
      actionId: fakeErrorIdPrefix + uuid.v4(),
      text: actionText
    };
    const unkErrorScene: ISceneView = {
      sceneId: fakeErrorIdPrefix + uuid.v4(),
      text: "",
      problemType: ProblemType.Unknown,
      isCanon: false,
    };
    const unkErrorTurn: ITurnView = {
      turnId: fakeErrorIdPrefix + uuid.v4(),
      historyId: fakeErrorIdPrefix + uuid.v4(),
      action: unkErrorAction,
      scene: unkErrorScene,
      isFirstTurn: isFirstTurn,
      isSaved: false,
    };
    return unkErrorTurn;
  };

  const requestBegin = async (actionText: string): Promise<IJourneyView | null> => {
    const beginReq: IBeginJourneyRequest = {
      gameId: gameId,
      firstTurnText: actionText
    };
    const response = await fetch("/api/v1/journies/begin", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(beginReq)
    });

    if (response.status === 401) {
      console.warn("No auth when submitting action.");
      setPreservedActionText(actionText);
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
      return null;
    } else if (!response.status.toString().startsWith("2")) {
      const failureJourney = getHandledFailureJourney(beginReq, response, actionText);
      return failureJourney;
    }

    if (response.body === null) {
      throw Error("response.body is null");
    }

    const reader = response.body.getReader();

    let segmentIndex = 0;
    let sceneText = "";
    let availableBuffer = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      const textChunk = decoder.decode(value);
      availableBuffer += textChunk;
      const chunkTermIndex = textChunk.indexOf(termChar);
      if (chunkTermIndex === -1) {
        continue;
      }
      let remainingBuffer = availableBuffer;
      while (true) {
        const remainingTermIndex = remainingBuffer.indexOf(termChar);
        if (remainingTermIndex === -1) {
          availableBuffer = remainingBuffer;
          break;
        }

        const beforeDelimText = remainingBuffer.substring(0, remainingTermIndex);
        const afterDelimText = remainingTermIndex === remainingBuffer.length - 1 ?
          "" :
          remainingBuffer.substring(remainingTermIndex + 1);
        remainingBuffer = afterDelimText;

        if (beforeDelimText.includes(`[${HttpErrorMessage.ServerError}]`)) {
          const failureJourney = getHandledFailureJourney(beginReq, response, actionText);
          return failureJourney;
        }

        if (segmentIndex === 0) {
          incrementallySetOverrideText(beforeDelimText);
          sceneText += beforeDelimText;
        } else if (segmentIndex === 1) {
          incrementallySetSecondaryText(beforeDelimText);
          sceneText += beforeDelimText;
        } else {
          const newJourneyResult = JSON.parse(beforeDelimText) as IBeginJourneyResult;
          const newJourney = newJourneyResult.journeyView;
          newJourney.history[0].scene.text = sceneText;
          setTokenBalance(newJourneyResult.updatedTokenBalance);
          return newJourney;
        }
        segmentIndex++;
      }
    }
    throw Error("Shouldn't reach here in requestBegin");
  };

  const getHandledFailureJourney = (
    beginReq: IBeginJourneyRequest, response: Response, actionText: string
  ) => {
    console.error({ beginReq });
    console.error({ response });
    const unkErrorTurn = getUnkErrorTurn(actionText, true);
    const unkErrJourney = {
      ...(initJourney as IJourneyView),
      ...{
        history: [unkErrorTurn]
      }
    };
    return unkErrJourney;
  };


  const requestTakeNextTurn = async (
    journeyId: string, historyId: string, currTurnId: string, actionText: string
  ): Promise<ITurnView | null> => {
    const turnReq: ITakeTurnRequest = {
      historyId,
      currentTurnId: currTurnId,
      actionText
    };
    const response = await fetch(`/api/v1/journies/${journeyId}/take-turn`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(turnReq)
    });

    if (response.status === 401) {
      console.warn("No auth when submitting action.");
      setPreservedActionText(actionText);
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
      return null;
    } else if (!response.status.toString().startsWith("2")) {
      console.error({ turnReq });
      console.error({ response });
      const unkErrorTurn = getUnkErrorTurn(actionText, true);
      return unkErrorTurn;
    }

    if (response.body === null) {
      throw Error("response.body is null");
    }

    const reader = response.body.getReader();

    let segmentIndex = 0;
    let sceneText = "";
    let availableBuffer = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      const textChunk = decoder.decode(value);
      availableBuffer += textChunk;
      const chunkTermIndex = textChunk.indexOf(termChar);
      if (chunkTermIndex === -1) {
        continue;
      }
      let remainingBuffer = availableBuffer;
      while (true) {
        const remainingTermIndex = remainingBuffer.indexOf(termChar);
        if (remainingTermIndex === -1) {
          availableBuffer = remainingBuffer;
          break;
        }

        const beforeDelimText = remainingBuffer.substring(0, remainingTermIndex);
        const afterDelimText = remainingTermIndex === remainingBuffer.length - 1 ?
          "" :
          remainingBuffer.substring(remainingTermIndex + 1);
        remainingBuffer = afterDelimText;

        if (beforeDelimText.includes(`[${HttpErrorMessage.ServerError}]`)) {
          console.error({ turnReq });
          console.error({ response });
          const unkErrorTurn = getUnkErrorTurn(actionText, true);
          return unkErrorTurn;
        }

        if (segmentIndex === 0) {
          incrementallySetOverrideText(beforeDelimText);
          sceneText += beforeDelimText;
        } else if (segmentIndex === 1) {
          incrementallySetSecondaryText(beforeDelimText);
          sceneText += beforeDelimText;
        } else {
          const newTurnResult = JSON.parse(beforeDelimText) as ITakeTurnResult;
          const newTurn = newTurnResult.turnView;
          newTurn.scene.text = sceneText;
          setTokenBalance(newTurnResult.updatedTokenBalance);
          return newTurn;
        }
        segmentIndex++;
      }
    }
    throw Error("Shouldn't reach here in requestTakeNextTurn");
  };

  let isIncrementingOverrideText = false;

  const incrementallySetOverrideText = async (text: string) => {
    isIncrementingOverrideText = true;
    const splitText = text.split(" ");
    if (splitText.length > 0) {
      let incrementalText = splitText[0];
      setLatestSceneOverrideText(incrementalText);
      if (splitText.length > 1) {
        for (const s of R.tail(splitText)) {
          await delay(30);
          incrementalText += " " + s;
          setLatestSceneOverrideText(incrementalText);
        }
      }
    }
    isIncrementingOverrideText = false;
  };

  const incrementallySetSecondaryText = async (text: string) => {
    while (isIncrementingOverrideText) {
      await delay(60);
    }
    const splitText = text.split(" ");
    if (splitText.length > 0) {
      let incrementalText = splitText[0];
      setLatestSceneSecondaryText(incrementalText);
      if (splitText.length > 1) {
        for (const s of R.tail(splitText)) {
          await delay(30);
          incrementalText += " " + s;
          setLatestSceneSecondaryText(incrementalText);
        }
      }
    }
  };

  const onSubmit = async (text: string) => {

    let updatedHistory = history;
    if (latestTurn) {
      updatedHistory = history.concat([latestTurn]);
      setHistory(updatedHistory);
    }

    setLatestAction({
      actionId: "",
      text: text,
    });
    setLatestScene({
      sceneId: "",
      text: "",
      isCanon: false,
    });
    setLatestSceneOverrideText("");
    setLatestSceneSecondaryText("");
    setIsBetweenSubmittingAndFocusing(true);
    setIsAwaitingScene(true);
    setTimeout(scrollToNewSceneReadingPosition, scrollDelayMs);

    let turn: ITurnView;
    if (!journeyId) {
      const newJourney = await requestBegin(text) as IJourneyView;
      setJourneyId(newJourney.journeyId);
      turn = newJourney.history[0];
    } else {
      const realTurns = updatedHistory.filter(h => !h.turnId.startsWith(fakeErrorIdPrefix));
      const currTurnId = R.last(realTurns)?.turnId as string;
      turn = await requestTakeNextTurn(
        journeyId as string,
        currHistoryId as string,
        currTurnId,
        text) as ITurnView;
    }
    if (!turn.turnId.startsWith(fakeErrorIdPrefix)) {
      setCurrHistoryId(turn.historyId);
    }

    setLatestScene(turn.scene);
    setLatestTurn(turn);
    setIsAwaitingScene(false);
  };

  const scrollToNewSceneReadingPosition = () => {
    try {
      scroller.scrollTo("active-scene-container", {
        containerId: props.pageId,
        offset: -50,
        duration: 250,
        delay: 0,
        smooth: "linear",
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const rewind = async (turnId: string) => {
    console.log({ topic: "revert", turnId });

    const isNewLastTurn = (t: ITurnView) => t.turnId === turnId;
    const newLatestTurn = R.find(isNewLastTurn, history) as ITurnView;
    const truncatedHistory = R.takeWhile(R.complement(isNewLastTurn), history);

    // Autosave new location
    const autosaveTurnLoc: ITurnLocation = {
      journeyId: journeyId as string,
      turnId: newLatestTurn.turnId,
      historyId: newLatestTurn.historyId,
    };
    try {
      await GameSaver.autosave(gameId, autosaveTurnLoc);
    } catch (e) {
      if (e instanceof AuthError) {
        loginWithRedirect({ appState: { returnTo: window.location.pathname } });
        return;
      }
      console.error(e);
      return;
    }

    setLatestSceneSecondaryText(undefined);
    setLatestSceneOverrideText(null);
    revertHistory(truncatedHistory, newLatestTurn);
  };

  const onRequestRevert = (
    turnId: string,
    onComplete: () => void,
    onError: () => void
  ) => {
    performSaveCheck(
      // Proceed
      () => { rewind(turnId).then(onComplete).catch(onError); },
      // Cancel
      onComplete,
    );
  };

  useEffect(() => {
    try {
      if (!!newScrollId) {
        scroller.scrollTo(newScrollId, {
          containerId: props.pageId,
          offset: -50,
          duration: 0,
          delay: 0,
          smooth: "linear",
        });
        clearNewScrollId();
      }
    } catch (error) {
      console.warn(error);
    }
  }, [newScrollId, props.pageId, clearNewScrollId]);

  return (
    <div className="ChatPanel">
      <div className="ChatPanel-top-spacer"></div>
      <div className="ChatPanel-group">
        {(history.length > 0 && !history[0].isFirstTurn) &&
          <HistoryLoader />
        }
        {openingView &&
          <Fragment>
            <div className="ChatPanel-cover-art">
              <img src="mithgard_cover_art.webp" alt="Mithgard cover art, showing a sprawling, coastal fantasy city with large ships at harbour" />
              <ChatPre viewType={ChatViewType.Cover}><ChatText text={`Welcome to Mithgard, a grand coastal city and the royal seat of King Bastion and his Spiranthian Queen, Gretel. Mithgard is an important hub of commerce in this expansive fantasy world.

You are a first-year, resident student at the prestigious Mithgard University of magic. With only a week left in the school year, you and other first-year students are feeling the pressure to finally declare which of the six major schools of magic you will major in.

However, this is the least worry on your mind. The people of Mithgard are on edge as rumours of disappearances swirl. With the number of missing children on the rise, you feel you can no longer ignore it.`} /></ChatPre>
            </div>
            <ChatScene
              gameId={gameId}
              isOpening={true}
              scene={openingView.scene}
              imgUrlOverride={openingView.imgUrlOverride}
              isLatest={!!history.length}
            />
          </Fragment>
        }
        {history.map(turn =>
          <Fragment key={turn.turnId}>
            <ChatAction key={turn.action.actionId} action={turn.action} />
            <ChatScene
              id={turn.scene.sceneId}
              gameId={gameId}
              key={turn.scene.sceneId}
              scene={turn.scene}
              turnLoc={{
                journeyId: journeyId as string,
                historyId: turn.historyId,
                turnId: turn.turnId,
              }}
              isSaved={turn.isSaved}
              isLatest={false}
              onRequestRevert={onRequestRevert}
            />
          </Fragment>
        )}
      </div>
      <div
        id="active-scene-container"
        className="ChatPanel-group"
        style={isBetweenSubmittingAndFocusing ? { height: "1000px" } : {}}
      >
        {latestAction &&
          <ChatAction id={latestActionElemId} action={latestAction} />
        }
        {latestScene &&
          <ChatScene
            gameId={gameId}
            scene={latestScene}
            isBeingFilled={isAwaitingScene}
            overrideText={latestSceneOverrideText}
            secondaryText={latestSceneSecondaryText}
            turnLoc={{
              journeyId: journeyId as string,
              historyId: currHistoryId as string,
              turnId: latestTurn?.turnId as string,
            }}
            isSaved={!!latestTurn?.isSaved}
            onSaved={() => setLatestTurn({ ...latestTurn as ITurnView, ...{ isSaved: true } })}
            isLatest={true}
          />
        }
        <ChatInput
          isHidden={isAwaitingScene || latestScene?.isDeath}
          onUserFocusingOnInput={onFocusInput}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );


}
