import { Fragment } from "react";

export default function PrivacyPolicy() {

    return (
        <Fragment>
<p><em>Last Updated: 2024-10-31</em></p>
<h2 id="information-we-collect">1. Information We Collect</h2>
<h3 id="personal-information">Personal Information</h3>
<p>The personal information EndlessGPT collects is intentionally
limited.</p>
<ul>
<li><p><strong>Email Address</strong>: Collected during account creation
for authentication, transactional communications, and marketing
purposes.</p></li>
<li><p><strong>Auth0 SSO Login ID</strong>: Used for secure
authentication and to maintain user sessions.</p></li>
</ul>
<h3 id="user-generated-content">User-Generated Content</h3>
<ul>
<li><p><strong>Game Actions and Scenes</strong>: We collect the text
inputs you provide and the resulting game content generated during your
gameplay.</p></li>
<li><p><strong>Warning</strong>: You must not include any private or
sensitive information in the text you submit during gameplay.</p></li>
</ul>
<h3 id="cookies">Cookies</h3>
<ul>
<li><p><strong>Login Cookies</strong>: Used solely for authentication
purposes and to maintain your user session. We do not use tracking
cookies.</p></li>
</ul>
<h2 id="how-we-use-your-information">2. How We Use Your Information</h2>
<ul>
<li><p><strong>Providing and Improving the Service</strong>: To operate,
maintain, and enhance our games.</p></li>
<li><p><strong>AI Training and Product Development</strong>: Your
gameplay data helps us improve our algorithms and develop new
features.</p></li>
<li><p><strong>Communications</strong>:</p>
<ul>
<li><p><strong>Transactional Emails</strong>: For updates related to
your account activity.</p></li>
<li><p><strong>Marketing Emails</strong>: Information about new
features, promotions, or updates. You can unsubscribe at any time by
following the instructions in the email.</p></li>
</ul></li>
<li><p><strong>Investigations</strong>: To monitor and address
violations of our Terms of Use or any illegal activities.</p></li>
<li><p><strong>Information from social login providers</strong>:
EndlessGPT accepts third-party login providers, such as Google,
Microsoft, GitHub, Facebook and Apple. Your Email Address and User ID
supplied by these providers is stored in a secure and encrypted
database. Use of these details is limited to the uses mentioned in this
section, and EndlessGPT does not retain additional personal information
from these providers.</p></li>
</ul>
<h2 id="sharing-and-disclosure-of-private-information">3. Sharing and
Disclosure of Private Information</h2>
<ul>
<li><p><strong>Third-Party Service Providers</strong>: Additional
personally identifiable information which you may supply to these
providers during the course of your use of EndlessGPT is not retained by
the EndlessGPT app.</p>
<ul>
<li><p><strong>AWS</strong>: We securely store your data in encrypted
form.</p></li>
<li><p><strong>Auth0</strong>: Provides secure authentication
services.</p></li>
<li><p><strong>Zoho</strong>: Support desk platform.</p></li>
<li><p><strong>Mailgun</strong>: Used to send you emails.</p></li>
<li><p><strong>Stripe</strong>: Used to securely manage your payment
details and complete transactions.</p></li>
</ul></li>
<li><p><strong>Legal Compliance</strong>: We may disclose your
information if required by law or to protect our legal rights.</p></li>
<li><p><strong>User Consent</strong>:</p>
<ul>
<li><p><strong>Sharing Feature</strong>: If you opt to share your
gameplay journeys, they may become publicly available.</p></li>
</ul></li>
<li><p><strong>Aggregated or Anonymized Data</strong>: May be used for
analytics or research without identifying individual users.</p></li>
</ul>
<h2 id="data-security">4. Data Security</h2>
<p>We prioritize your data security and employ various measures to
protect your information:</p>
<ul>
<li><p><strong>Encryption</strong>: Personal data is encrypted during
transmission and storage.</p></li>
<li><p><strong>Security Measures</strong>: We implement technical and
organizational measures to prevent unauthorized access, alteration, or
destruction of data.</p></li>
<li><p><strong>Regular Reviews</strong>: Our security practices are
regularly reviewed and updated.</p></li>
</ul>
<h2 id="international-data-transfers">5. International Data
Transfers</h2>
<p>Your information may be transferred to and processed in countries
other than your own, including New Zealand and the United States. By
using our service, you consent to such transfers. We adhere to
applicable data protection regulations, including New Zealand's Privacy
Act 2020.</p>
<h2 id="childrens-privacy">6. Children's Privacy</h2>
<p>Our service is not intended for minors. Minors are not permitted to
create or hold an account on EndlessGPT. If we become aware that a minor
has provided us with personal information, we will take steps to delete
such information promptly.</p>
<h2 id="third-party-links">7. Third-Party Links</h2>
<p>Our service may contain links to external websites. We have no
control over the content or privacy practices of these sites and
encourage you to review their privacy policies.</p>
<h2 id="changes-to-this-privacy-policy">8. Changes to This Privacy
Policy</h2>
<p>We may update this Privacy Policy periodically. Major changes will be
communicated via email to the address associated with your account.
Continued use of the service after changes are posted constitutes your
acceptance of the revised policy.</p>
<h2 id="jurisdiction-and-governing-law">9. Jurisdiction and Governing
Law</h2>
<p>This Privacy Policy is governed by the laws of New Zealand. By
accessing our service, you consent to the exclusive jurisdiction of New
Zealand courts in all disputes arising out of or relating to the use of
the service.</p>
        </Fragment>
    );
};
