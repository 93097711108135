import { useState, MouseEvent } from "react";
import "./SaveButton.css";
import debounce from "lodash.debounce";

export enum SaveButtonStates {
    Unsaved = "Unsaved",
    Saving = "Saving",
    Saved = "Saved",
    Error = "Error",
}

interface IProps {
    onPressed: () => void;
    saveState: SaveButtonStates;
}

export default function SaveButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    const buttonTips = {
        [SaveButtonStates.Unsaved]: "Create a save game at this scene",
        [SaveButtonStates.Saving]: "Game saving",
        [SaveButtonStates.Saved]: "Game was saved at this scene successfully.",
        [SaveButtonStates.Error]: "There was an error saving the game. Click to retry.",
    };
    const buttonTip = buttonTips[props.saveState];

    const iconClasses = {
        [SaveButtonStates.Unsaved]: "fas fa-save",
        [SaveButtonStates.Saving]: "fa-solid fa-hourglass-start",
        [SaveButtonStates.Saved]: "fa-solid fa-check-double green",
        [SaveButtonStates.Error]: "fa-solid fa-circle-exclamation red",
    };
    const iconClass = iconClasses[props.saveState];

    return (
        <button
            className={isDepressed ? "SaveButton SaveButton-clicked" : "SaveButton"}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title={buttonTip}
        >
            <i className={iconClass}></i>
        </button>
    );
};
