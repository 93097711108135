import "./SaveCheckModal.css";
import Modal from "react-modal";
import { useSaveCheckStore } from "./SaveCheckStore";
import { playStore } from "PlayStore";
import { IGameInProgressView } from "backend-models/services/games/games-api-models/IGameInProgressView";
import { GameSaver } from "components/Chat/ChatScene/GameSaver";
import { AuthError } from "auth/AuthError";
import { useAuth0 } from "@auth0/auth0-react";
import CloseButton from "components/CloseButton/CloseButton";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";

Modal.setAppElement("#root");

export default function SaveCheckModal() {
    const { isOpen, closeSaveCheck, onProceed, onCancel, gameToSaveFirst }
        = useSaveCheckStore();
    const { loginWithRedirect } = useAuth0();

    const { game, journeyId } = playStore();

    const handleSave = async () => {
        await saveGameAtTurn(gameToSaveFirst as ITurnView);
        closeSaveCheck();
        onProceed();
    };

    const handleDontSave = () => {
        closeSaveCheck();
        onProceed();
    };

    const handleCancel = () => {
        closeSaveCheck();
        onCancel();
    };

    const saveGameAtTurn = async (saveTurn: ITurnView) => {
        const currGame = game as IGameInProgressView;
        const gameId = currGame.id as string;
        const saveTurnLoc: ITurnLocation = {
            journeyId: journeyId as string,
            turnId: saveTurn.turnId,
            historyId: saveTurn.historyId,
        };
        try {
            await GameSaver.save(
                gameId,
                saveTurnLoc,
                saveTurn.scene?.picId,
                saveTurn.scene?.text);
        } catch (e) {
            if (e instanceof AuthError) {
                loginWithRedirect({ appState: { returnTo: window.location.pathname } });
                return;
            }
            console.error(e);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            contentLabel="Save Current Progress first?"
            className="SaveCheckModal Modal"
        >
            <div className="Modal-content">
                <CloseButton onPressed={handleCancel} />
                <div className="SaveCheckModal-header">
                    <h2>Save current progress first?</h2>
                </div>
                <div className="SaveCheckModal-button-pane">
                    <button onClick={handleSave}>Save</button>
                    <button className="warning-button" onClick={handleDontSave}>Don't Save</button>
                    <button className="cancel-button" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </Modal>
    );
};
