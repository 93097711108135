export class WebConfig {
    public static readonly AUTH0_DOMAIN =
        process.env.REACT_APP_AUTH0_DOMAIN as string;

    public static readonly AUTH0_CLIENT_ID =
        process.env.REACT_APP_AUTH0_CLIENT_ID as string;

    public static readonly IS_OUT_OF_SERVICE =
        process.env.REACT_APP_IS_OUT_OF_SERVICE as string;

    public static readonly IS_PAYMENT_DISABLED =
        process.env.REACT_APP_IS_PAYMENT_DISABLED as string;

    public static readonly GCP_CAPTCHA_KEY =
        process.env.REACT_APP_GCP_CAPTCHA_KEY as string;

    public static readonly UNTETHERED = false;
}
