import ContentLink from "components/ContentLink/ContentLink";
import "./TermsOfUsePage.css";
import Page from "components/Page/Page";
import PolicyWarning from "components/Policies/PolicyWarning/PolicyWarning";
import TermsOfUse from "components/Policies/TermsOfUse/TermsOfUse";
import { useNavigate } from "react-router-dom";
import { ContactTicketSubmitter } from "components/forms/ContactForm/ContactTicketSubmitter";

export default function TermsOfUsePage() {
    const pageId = "terms-of-use-page";
    const navigate = useNavigate();

    const onContactClick = () => {
        ContactTicketSubmitter.navigateToContactWithPrefill(
            "Terms of Use",
            null,
            () => { navigate("/contact?purpose=Support"); },
        );
    };

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingText="Terms of Use"
            hasCloseButton={true}
        >
            <div className="TermsOfUsePage">
                <h2 id="introduction">Introduction</h2>
                <p>Welcome to EndlessGPT. These Terms of Use ("Terms") govern your access to and use of our AI-driven, text-based adventure game ("Service"). By accessing or using our Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Service.</p>
                <p>Your use of the Service is also subject to our <ContentLink text="Privacy Policy" path="/privacy-policy" />, which is incorporated herein by reference.</p>
                <PolicyWarning />
                <hr />
                <TermsOfUse />
                <hr />
                <h2 id="contact-us">Contact Us</h2>
                <p>If you have any questions or concerns about these Terms, please contact us using the contact form available on our <ContentLink text="Contact Us page" onClick={onContactClick} />.</p>
                <p><b>Thank you for using EndlessGPT. We hope you enjoy your adventure!</b></p>
            </div>
        </Page >
    );
}
