import "./TextBox.css";
import { useRef, useState, ChangeEvent, useEffect } from "react";
import { ValidityCheck } from "../ValidityCheck";
import { IValidity } from "backend-models/app-api-models/validation/IValidity";

interface IProps {
    initialVal?: string;
    onChanged: (val: string, validity: IValidity) => void;
    placeholderText?: string;
    validityTest?: ValidityCheck;
    forceShowInvalid?: boolean;
    maxLength: number;
}

export default function TextBox(props: IProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [val, setVal] = useState(props.initialVal || "");
    // TODO support a prop to start focused
    // Would need to change the way isFocused is initialized
    // and, of course, actually focus the input.
    // With mobile phones these days, it's not advisable,
    // because it makes keyboards pop up over half the screen
    // before the user is ready to interact with the form.
    const [isFocused, setIsFocused] = useState(false);
    const [hasChangedSinceFocusing, setHasChangedSinceFocusing] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const handleFocus = () => {
        setHasChangedSinceFocusing(false);
        setIsFocused(true);
        if (props.placeholderText && !val) {
            inputRef.current?.select();
        }
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const curateVal = (actualVal: string) => {
        return actualVal.trim();
    };

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setHasChangedSinceFocusing(true);
        setVal(event.target.value);
        const defactoValidity: IValidity = {
            isValid: true,
            failureReasons: [],
        };
        const curatedVal = curateVal(event.target.value);
        const validity = props.validityTest ? props.validityTest(curatedVal) : defactoValidity;
        setIsValid(validity.isValid);
        props.onChanged(curatedVal, validity);
    };

    const validityTestRef = useRef(props.validityTest);
    const onChangedRef = useRef(props.onChanged);
    useEffect(() => {
        const initialVal = props.initialVal || "";
        setVal(initialVal);
        const defactoValidity: IValidity = {
            isValid: true,
            failureReasons: [],
        };
        const curatedVal = curateVal(initialVal);
        const validity = validityTestRef?.current ?
            validityTestRef.current(curatedVal) :
            defactoValidity;
        setIsValid(validity.isValid);
        if (onChangedRef?.current) {
            onChangedRef.current(curatedVal, validity);
        }
    }, [props.initialVal, validityTestRef, onChangedRef]);


    const shouldShowPlaceholderText =
        (!val) &&
        !!(props.placeholderText) &&
        ((!isFocused) || (isFocused && !hasChangedSinceFocusing));

    const shouldShowInvalid = props.forceShowInvalid || ((!shouldShowPlaceholderText) && !isValid);

    return (
        <textarea
            ref={inputRef}
            className={
                "TextBox" +
                (shouldShowPlaceholderText ? " TextBox-placeholder" : "") +
                (shouldShowInvalid ? " TextBox-invalid" : "")}
            value={shouldShowPlaceholderText ? props.placeholderText : val}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            maxLength={props.maxLength}
        />
    );
};
