import "./FaqAnswer.css";
import { ReactNode } from "react";


interface IProps {
    children: ReactNode;
}

export default function FaqAnswer(props: IProps) {

    return (
        <div className="FaqAnswer">
            {props.children}
        </div>
    );
};
