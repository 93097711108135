import "./ChatScene.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import FlashingCursor from "components/FlashingCursor/FlashingCursor";
import LazyPicture from "components/LazyPicture/LazyPicture";
import { ChatViewType } from "components/Chat/ChatViewType";
import { ErrorCopy } from "copy/ErrorCopy";
import SaveButton, { SaveButtonStates } from "components/SaveButton/SaveButton";
import { GameSaver } from "./GameSaver";
import { AuthError } from "auth/AuthError";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { LazyPicSize } from "components/LazyPicture/LazyPicSize";
import RevertButton, { RevertButtonStates } from "components/RevertButton/RevertButton";
import RecommendedAction from "../RecommendedAction/RecommendedAction";
import HelpButton from "components/Help/HelpButton/HelpButton";
import { useDaydreamHelpStore } from "components/Help/DaydreamHelpModal/DaydreamHelpStore";
import { useGameOverHelpStore } from "components/Help/GameOverHelpModal/GameOverHelpStore";
import * as R from "ramda";
import { ProblemType } from "backend-models/services/engine/histories/histories-api-models/ProblemType";
import { ISceneView } from "backend-models/services/journies/journies-api-models/ISceneView";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";

interface IProps {
    id?: string;
    gameId: string;
    turnLoc?: ITurnLocation,
    scene: ISceneView;
    secondaryText?: string;
    isBeingFilled?: boolean;
    overrideText?: string | null;
    imgUrlOverride?: string | null;
    isOpening?: boolean;
    isSaved?: boolean;
    isLatest: boolean;
    onSaved?: () => void,
    onRequestRevert?: (
        turnId: string,
        onComplete: () => void,
        onError: () => void
    ) => void;
}

export default function ChatScene(props: IProps) {
    const { loginWithRedirect } = useAuth0();
    const [saveState, setSaveState] = useState(
        props.isSaved ? SaveButtonStates.Saved : SaveButtonStates.Unsaved);
    const [revertState, setRevertState] = useState(RevertButtonStates.Ready);
    const { openDaydreamHelp } = useDaydreamHelpStore();
    const { openGameOverHelp } = useGameOverHelpStore();

    useEffect(() => {
        setSaveState(props.isSaved ? SaveButtonStates.Saved : SaveButtonStates.Unsaved);
    }, [props.scene.sceneId, props.isSaved]);

    const viewType = props.scene.problemType ? ChatViewType.Problem : ChatViewType.Scene;
    const doShowSaveButton =
        !props.isOpening
        && !props.isBeingFilled
        && viewType === ChatViewType.Scene
        && props.scene.isCanon;
    const specialActionSnippets = [
        "You have:",
        "talents are:",
        "you find:",
    ];
    const isDaydream =
        !props.isOpening
        && !props.isBeingFilled
        && viewType === ChatViewType.Scene
        && !props.scene.isCanon
        && !R.any(sas => props.scene.text.includes(sas), specialActionSnippets);
    const doShowRevertButton =
        !props.isOpening
        && !props.isBeingFilled
        && viewType === ChatViewType.Scene
        && props.scene.isCanon
        && !props.isLatest;

    const getText = (): string => {
        switch (props.scene.problemType) {
            case ProblemType.ActionViolation:
                return ErrorCopy.ActionViolation;
            case ProblemType.SceneViolation:
                return ErrorCopy.SceneViolation;
            case ProblemType.ModelTimeout:
                return ErrorCopy.Timedout;
            case ProblemType.NoScene:
                return ErrorCopy.UnknownServerPlayError;
            case ProblemType.Unknown:
                return ErrorCopy.UnknownServerPlayError;
            default:
                return props.overrideText || props.scene.text;
        }
    };
    const text = getText();

    const saveGame = async () => {
        if (saveState !== SaveButtonStates.Unsaved && saveState !== SaveButtonStates.Error) {
            return;
        }
        try {
            setSaveState(_ => SaveButtonStates.Saving);
            await GameSaver.save(
                props.gameId,
                props.turnLoc as ITurnLocation,
                props.scene.picId as string,
                text);
            if (props.onSaved) {
                props.onSaved();
            }
            setSaveState(_ => SaveButtonStates.Saved);
        } catch (e) {
            setSaveState(_ => SaveButtonStates.Error);
            if (e instanceof AuthError) {
                loginWithRedirect({ appState: { returnTo: window.location.pathname } });
                return;
            }
            console.error(e);
        }
    };

    const revertGame = () => {
        if (props.onRequestRevert) {
            setRevertState(_ => RevertButtonStates.Saving);
            props.onRequestRevert(
                (props.turnLoc as ITurnLocation).turnId,
                () => { setRevertState(_ => RevertButtonStates.Ready); },
                () => { setRevertState(_ => RevertButtonStates.Error); },
            );
        }
    };

    const doUseWaitText = !!(
        props.overrideText
        && props.overrideText.length > 0
        && !props.overrideText.includes("Game Over")
    );



    return (
        <ChatBubble viewType={viewType} id={props.id}>
            <ChatPreContainer>
                <ChatPre viewType={viewType}>
                    <ChatText
                        text={text}
                        secondaryText={props.scene.problemType ? "" : props.secondaryText}
                        isDeath={props.scene?.isDeath}
                    />
                </ChatPre>
                {props.isBeingFilled &&
                    <FlashingCursor useWaitText={doUseWaitText} />
                }
            </ChatPreContainer>
            {(props.scene.picId || props.imgUrlOverride) &&
                <LazyPicture
                    pictureId={props.scene.picId}
                    imgUrlOverride={props.imgUrlOverride}
                    size={LazyPicSize.Standard}
                />
            }
            {props.isOpening &&
                <ChatPreContainer>
                    <ChatPre viewType={ChatViewType.SuggestedActions}>
                        <ChatText text="Example actions:" />
                        <ul className="ChatScene-suggestions">
                            <li><RecommendedAction text="look around" /></li>
                            <li><RecommendedAction text="whisper, 'hey, have you chosen your major yet?'" /></li>
                            <li><RecommendedAction text="read the titles of my books" /></li>
                            <li><RecommendedAction text="exit library" /></li>
                        </ul>
                    </ChatPre>
                </ChatPreContainer>
            }
            {(doShowSaveButton || doShowRevertButton || isDaydream) &&
                <div className="ChatScene-tray">
                    {doShowRevertButton &&
                        <RevertButton onPressed={revertGame} buttonState={revertState} />
                    }
                    {props.scene?.isDeath &&
                        <HelpButton autoMargin={true} onPressed={openGameOverHelp} />
                    }
                    {doShowSaveButton &&
                        <SaveButton onPressed={saveGame} saveState={saveState} />
                    }
                    {isDaydream &&
                        <HelpButton onPressed={openDaydreamHelp} />
                    }
                </div>
            }
        </ChatBubble >
    );
}
