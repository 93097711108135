import FaqAnswer from "components/Help/FaqAnswer/FaqAnswer";
import FaqQuestion from "components/Help/FaqQuestion/FaqQuestion";
import HelpSection from "components/Help/HelpSection/HelpSection";
import DaydreamAnswer from "./DaydreamAnswer/DaydreamAnswer";
import DeathFaqs from "./DeathFaqs/DeathFaqs";

export default function Faq() {

    return (
        <HelpSection>
            <img src="help/faq.webp" alt="A fantasy book with question marks flying out" />
            <FaqQuestion><p>How can I get stronger?</p></FaqQuestion>
            <FaqAnswer>
                <p>The possibilities are endless, but here are some sure bets:</p>
                <ul>
                    <li>Attend magic classes.</li>
                    <li>Look for useful spells in books.</li>
                    <li>Ask someone stronger to teach you.</li>
                    <li>Go shopping for better equipment.</li>
                    <li>Look for a weapons trainer.</li>
                    <li>Seek friends to join your party.</li>
                    <li>Go to the University Menagerie and ask about forming an Animagic bond to gain an animal ally.</li>
                    <li>Ask at a tavern for quests and reap the rewards.</li>
                </ul>
            </FaqAnswer>
            <FaqQuestion><p>Why does it say I'm daydreaming?</p></FaqQuestion>
            <DaydreamAnswer />
            <FaqQuestion><p>An NPC told me a missing child was in Midlight Forest, but I went there and I didn't find the child.</p></FaqQuestion>
            <FaqAnswer><p>Midlight Forest is a very big place, so wandering through it alone is like trying to find a needle in a haystack. Perhaps you could ask around for a more precise location, or you could hire a tracker, use a hound to follow a scent, organise a search party or make clever use of some time magic to see what happened? The possibilities are endless, but you need a solution which fits the problem.</p></FaqAnswer>
            <FaqQuestion><p>I said something to an NPC and didn't they reply.</p></FaqQuestion>
            <FaqAnswer><p>Maybe they don't have anything to say to you. Did you think about that? Anyway, here are some follow-up actions you could try:</p>
                <ul className="HelpSection-actions">
                    <li>wait for a reply</li>
                    <li>i look at them expectantly</li>
                    <li>'well? do you have anything to say for yourself?</li>
                    <li>'you know, it's rude to ignore people</li>
                </ul>
            </FaqAnswer>
            <FaqQuestion><p>I did something and nothing happened</p></FaqQuestion>
            <FaqAnswer><p>Not every action will have a useful result. You have complete freedom of action in EndlessGPT, so you could stand around picking your nose if you want. You are not guaranteed a rewarding outcome to every action. That said, if you think something more should have happened, you can try:</p>
                <ul className="HelpSection-actions">
                    <li>see what happens</li>
                    <li>wait</li>
                    <li>keep watching</li>
                    <li>examine the results</li>
                </ul>
            </FaqAnswer>
            <DeathFaqs />
            <FaqQuestion><p>There's something right there in the picture, but it wont let me interact with it.</p></FaqQuestion>
            <FaqAnswer><p>The pictures are just to add atmosphere, and the image generation AI may add details which are not really in the scene. Always treat the text as the source of truth.</p></FaqAnswer>
            <FaqQuestion><p>The game has gone weird. What it's saying doesn't make sense.</p></FaqQuestion>
            <FaqAnswer><p>Sorry about that! AI never gets it right 100% of the time. Please just rewind to the point before it went weird (<i className="fa-solid fa-backward"></i>) and try again. Even copying and pasting in the same action may work better the second time.</p></FaqAnswer>
        </HelpSection>
    );
};
