import Faq from "components/Help/Faq/Faq";
import Page from "components/Page/Page";

export default function FaqPage() {
    const pageId = "faq-page";

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingIconClass="fa-regular fa-circle-question"
            headingText="F.A.Q."
            hasCloseButton={true}
        >
            <Faq />
        </Page >
    );
}
