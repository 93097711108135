import { useState, MouseEvent } from "react";
import "./RevertButton.css";
import debounce from "lodash.debounce";

export enum RevertButtonStates {
    Ready = "Ready",
    Saving = "Saving",
    Error = "Error",
}

interface IProps {
    onPressed: () => void;
    buttonState: RevertButtonStates;
}

export default function RevertButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    const buttonTips = {
        [RevertButtonStates.Ready]: "Rewind the game to this scene.",
        [RevertButtonStates.Saving]: "Current game scene saving",
        [RevertButtonStates.Error]: "There was an error saving the current scene. Click to retry.",
    };
    const buttonTip = buttonTips[props.buttonState];

    const iconClasses = {
        [RevertButtonStates.Ready]: "fa-solid fa-backward",
        [RevertButtonStates.Saving]: "fa-solid fa-hourglass-start",
        [RevertButtonStates.Error]: "fa-solid fa-circle-exclamation red",
    };
    const iconClass = iconClasses[props.buttonState];

    return (
        <button
            className={isDepressed ? "RevertButton RevertButton-clicked" : "RevertButton"}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title={buttonTip}
        >
            <i className={iconClass}></i>
        </button>
    );
};
