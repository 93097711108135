import "./CaptchaModal.css";
import Modal from "react-modal";
import CloseButton from "components/CloseButton/CloseButton";
import { useCaptchaModalStore } from "./CaptchaModalStore";
import { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { WebConfig } from "WebConfig";
import DiceButton from "components/Captcha/DiceButton/DiceButton";

Modal.setAppElement("#root");

export default function CaptchaModal() {
    const { isOpen, closeCaptcha, onCompleted, onCancel } = useCaptchaModalStore();
    const recaptchaRef = createRef();
    const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);

    const handleCompleted = async (captchaToken: string) => {
        closeCaptcha();
        onCompleted(captchaToken);
    };

    const handleCancel = () => {
        closeCaptcha();
        onCancel();
    };

    const onCaptchaLoaded = () => {
        setIsCaptchaLoaded(true);
        console.log("Captcha loaded");
    };


    const executeCaptcha = async () => {
        if (!isCaptchaLoaded) {
            return;
        }
        try {
            const cap = recaptchaRef.current as any;
            const token = await cap.executeAsync();
            if (token) {
                handleCompleted(token);
            } else {
                handleCancel();
            }
        } catch (e) {
            console.error("Error executing captcha");
            console.error(e);
            handleCancel();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            contentLabel="Captcha check"
            className="CaptchaModal Modal"
        >
            <div className="Modal-content">
                <div className="CaptchaModal-gif-preload" aria-hidden="true">
                    <img src="1d20/1d20.gif" alt="hidden" />
                    <img src="1d20/1d20-19.gif" alt="hidden" />
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={WebConfig.GCP_CAPTCHA_KEY}
                    asyncScriptOnLoad={onCaptchaLoaded}
                />
                <CloseButton onPressed={handleCancel} />
                <div className="CaptchaModal-header">
                    <h2>Your humanity is in question</h2>
                </div>
                <h3>Roll 1d20 for a charisma check</h3>
                <div className="CaptchaModal-button-pane">
                    <DiceButton onClick={executeCaptcha} />
                </div>
            </div>
        </Modal>
    );
};
