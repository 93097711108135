import { create } from "zustand";
import { playStore } from "PlayStore";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";

interface ISaveCheckState {
    isOpen: boolean;
    gameToSaveFirst: ITurnView | null;
    onProceed: () => void;
    onCancel: () => void;
    performSaveCheck: (
        proceedCallback: () => void,
        cancelCallback: () => void) => void;
    closeSaveCheck: () => void;
}

export const useSaveCheckStore = create<ISaveCheckState>((set) => {

    const getLatestSaveableTurn = () => {
        const { latestTurn, history } = playStore.getState();
        if (getIsSaveable(latestTurn)) {
            return latestTurn;
        }
        for (let i = history.length - 1; i >= 0; i--) {
            if (getIsSaveable(history[i])) {
                return history[i];
            }
        }
        return null;
    };

    const getIsSaveable = (turn: ITurnView | null): boolean => {
        if (!turn) {
            return false;
        }
        const scene = turn.scene;
        return scene.isCanon && !scene.problemType;
    };

    return {
        isOpen: false,
        gameToSaveFirst: null,
        onProceed: () => { },
        onCancel: () => { },
        performSaveCheck: (proceedCallback, cancelCallback) =>
            set(() => {
                const latestSaveableTurn = getLatestSaveableTurn();
                if ((!latestSaveableTurn) || latestSaveableTurn.isSaved) {
                    console.log("Game doesn't need saving.");
                    proceedCallback();
                    return {};
                }
                return {
                    isOpen: true,
                    gameToSaveFirst: latestSaveableTurn,
                    onProceed: proceedCallback,
                    onCancel: cancelCallback,
                };
            }),
        closeSaveCheck: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

