import GameManual from "components/Help/GameManual/GameManual";
import Page from "components/Page/Page";

export default function GameManualPage() {
    const pageId = "game-manual-page";

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingIconClass="fa-solid fa-book-open"
            headingText="Game Manual"
            hasCloseButton={true}
        >
            <GameManual />
        </Page >
    );
}
