import { AnyZodObject, ZodIssue, ZodTypeAny } from "zod";
import { IValidity } from "./IValidity";
import * as R from "ramda";
import humanizeString from "humanize-string";

export class Validor {
    public static getIsValid(
        schema: AnyZodObject,
        model: unknown,
        useFriendlyFields: boolean = true
    ) {
        return this.getValidity(schema, model, {}, useFriendlyFields);
    }

    public static getIsValidMapped(
        schema: AnyZodObject,
        model: unknown,
        fieldMap: { [key: string]: string; },
    ) {
        return this.getValidity(schema, model, fieldMap, true);
    }

    public static getIsValidField(
        schema: ZodTypeAny,
        model: unknown,
        friendlyName: string,
    ) {
        return this.getValidity(schema, model, { "": friendlyName }, true);
    }

    private static getValidity(
        schema: ZodTypeAny,
        model: unknown,
        fieldMap: { [key: string]: string; },
        useFriendlyFields: boolean,
    ): IValidity {
        const zResult = schema.safeParse(model);
        if (zResult.success) {
            return {
                isValid: true,
                failureReasons: [],
            };
        }
        if (!zResult.error) {
            throw Error("Validation failed for no reason");
        }
        const failureReasons = zResult.error?.errors.map(
            (e) => this.issue2Reason(e, useFriendlyFields, fieldMap));
        const failedValidity = {
            isValid: false,
            failureReasons,
        };
        return failedValidity;
    }

    private static issue2Reason(
        error: ZodIssue,
        useFriendlyFields: boolean,
        fieldMap: { [key: string]: string; },
    ): string {
        const field = (R.last(error.path) || "").toString();
        const suppliedFriendlyName = fieldMap[field];
        const friendlyField = suppliedFriendlyName ?
            suppliedFriendlyName :
            (useFriendlyFields ? humanizeString(field) : field);
        if (error.message === "String must contain at least 1 character(s)") {
            return `${friendlyField} is required.`;
        }
        return `${friendlyField} - ${error.message}.`;
    }
}
