import { create } from "zustand";

interface IDaydreamHelpState {
    isOpen: boolean;
    openDaydreamHelp: () => void;
    closeDaydreamHelp: () => void;
}

export const useDaydreamHelpStore = create<IDaydreamHelpState>((set) => {

    return {
        isOpen: false,
        openDaydreamHelp: () =>
            set(() => {
                return {
                    isOpen: true,
                };
            }),
        closeDaydreamHelp: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

