import { create } from "zustand";

interface IContactFormState {
    subjectPrefill: string | null;
    setSubjectPrefill: (text: string | null) => void;
    messagePrefill: string | null;
    setMessagePrefill: (text: string | null) => void;
    clearPrefills: () => void;
}

export const contactFormStore = create<IContactFormState>(set => ({
    subjectPrefill: null,
    setSubjectPrefill: (text: string | null) => set(() => ({ subjectPrefill: text })),
    messagePrefill: null,
    setMessagePrefill: (text: string | null) => set(() => ({ messagePrefill: text })),
    clearPrefills: () => set(() => ({ subjectPrefill: null, messagePrefill: null })),
}));
