import { ReactNode } from "react";
import "./HelpSection.css";


interface IProps {
    children: ReactNode;
}

export default function HelpSection(props: IProps) {

    return (
        <div className="HelpSection">
            {props.children}
        </div>
    );
};
