import { ReactNode } from "react";
import "./FaqQuestion.css";


interface IProps {
    children: ReactNode;
}

export default function FaqQuestion(props: IProps) {

    return (
        <div className="FaqQuestion">
            {props.children}
        </div>
    );
};
