import React, { FC, useState, useEffect, useCallback, useMemo, ReactNode } from "react";
import "./SwitchSelector.css";

const CLASS_NAMES_PREFIX = "switch-selector";

const defaultColors = {
    backgroundColor: "#ecf0f1",
    selectedBackgroundColor: "#2ecc71",
    fontColor: "#000",
    selectedFontColor: "#fff",
} as const;

export type OptionType<T = unknown> = {
    label: ReactNode;
    value: T;
    selectedBackgroundColor?: string;
    fontColor?: string;
    selectedFontColor?: string;
};

interface SwitchSelectorProps {
    options: Array<OptionType>;
    onChange?: <T>(selectedOptionValue: T | unknown) => void;
    initialSelectedIndex?: number;
    forcedSelectedIndex?: number;
    disabled?: boolean;
    name?: string;
    // Styling props
    border?: string | number;
    backgroundColor?: string;
    selectedBackgroundColor?: string;
    wrapperBorderRadius?: number | string;
    optionBorderRadius?: number | string;
    fontSize?: number;
    fontColor?: string;
    selectedFontColor?: string;
    selectionIndicatorMargin?: number;
}

const SwitchSelector: FC<SwitchSelectorProps> = (props) => {
    const { onChange = (): void => { }, options = [], initialSelectedIndex = 0 } = props;
    const canApplyInitialSelectedIndex = !!options[initialSelectedIndex];
    const [selectedIndex, setSelectedIndex] = useState(
        canApplyInitialSelectedIndex ? initialSelectedIndex : 0
    );

    const {
        border = 0,
        backgroundColor = defaultColors.backgroundColor,
        selectedBackgroundColor = defaultColors.selectedBackgroundColor,
        wrapperBorderRadius = 20,
        optionBorderRadius = 18,
        fontSize = 14,
        fontColor = defaultColors.fontColor,
        selectedFontColor = defaultColors.selectedFontColor,
        selectionIndicatorMargin = 2,
        forcedSelectedIndex,
        disabled = false,
        name,
    } = props;

    useEffect(() => {
        if (
            forcedSelectedIndex !== undefined &&
            !!options[forcedSelectedIndex] &&
            forcedSelectedIndex !== selectedIndex
        ) {
            setSelectedIndex(forcedSelectedIndex);
        }
    }, [forcedSelectedIndex, options, selectedIndex]);

    const handleOnClick = useCallback(
        (index: number): void => {
            if (!disabled && index !== selectedIndex) {
                setSelectedIndex(index);
                onChange(options[index].value);
            }
        },
        [disabled, onChange, options, selectedIndex]
    );

    const renderedOptions = useMemo(() => {
        return options.map((option, index) => {
            const isSelected = selectedIndex === index;
            const optionId = `${name ?? "rss"}-option-${index}`;
            const isRawText = typeof option.label === "string";

            const labelRawTextStyle = isRawText
                ? {
                    fontSize: `${fontSize}px`,
                    color: isSelected
                        ? option.selectedFontColor || selectedFontColor
                        : option.fontColor || fontColor,
                }
                : {};

            const optionItemLabelClassName = [
                `${CLASS_NAMES_PREFIX}-option-item-label`,
                isSelected ? "selected" : "",
                disabled ? "disabled" : "",
            ]
                .join(" ")
                .trim();

            return (
                <div key={optionId} className={`${CLASS_NAMES_PREFIX}-option-item`}>
                    <label
                        className={optionItemLabelClassName}
                        htmlFor={optionId}
                        style={labelRawTextStyle}
                    >
                        <input
                            type="radio"
                            id={optionId}
                            name={name}
                            onChange={(): void => handleOnClick(index)}
                            checked={isSelected}
                            aria-checked={isSelected}
                            tabIndex={isSelected ? 0 : -1}
                            className={`${CLASS_NAMES_PREFIX}-option-input`}
                        />
                        {option.label}
                    </label>
                </div>
            );
        });
    }, [
        disabled,
        fontColor,
        fontSize,
        handleOnClick,
        name,
        options,
        selectedFontColor,
        selectedIndex,
    ]);

    if (!options.length) return null;

    const optionsAmount = options.length;
    const beforeTop = `${(selectedIndex / optionsAmount) * 100}%`;

    const wrapperStyle = {
        "--wrapper-border-radius":
            typeof wrapperBorderRadius === "number" ? `${wrapperBorderRadius}px` : wrapperBorderRadius,
        "--border": typeof border === "number" ? `${border}px` : border,
        "--background-color": backgroundColor,
        "--selected-background-color":
            options[selectedIndex]?.selectedBackgroundColor || selectedBackgroundColor,
        "--option-border-radius":
            typeof optionBorderRadius === "number" ? `${optionBorderRadius}px` : optionBorderRadius,
        "--selection-indicator-margin": `${selectionIndicatorMargin}px`,
        "--options-amount": optionsAmount,
        "--before-top": beforeTop,
        "--font-size": `${fontSize}px`,
        "--font-color": fontColor,
        "--selected-font-color": selectedFontColor,
    } as React.CSSProperties;

    const wrapperClassName = [
        `${CLASS_NAMES_PREFIX}-wrapper`,
        disabled ? "disabled" : "",
    ]
        .join(" ")
        .trim();

    return (
        <div
            className={wrapperClassName}
            style={wrapperStyle}
            role="radiogroup"
            aria-labelledby={name}
        >
            {renderedOptions}
        </div>
    );
};

export default SwitchSelector;
