import FaqAnswer from "components/Help/FaqAnswer/FaqAnswer";
import FaqQuestion from "components/Help/FaqQuestion/FaqQuestion";
import { Fragment } from "react";

export default function DeathFaqs() {

    return (
        <Fragment>
            <FaqQuestion><p>I died. What now?</p></FaqQuestion>
            <FaqAnswer><p>Rewind your action (click the <i className="fa-solid fa-backward"></i> on an earlier turn) and try something different.</p></FaqAnswer>
            <FaqQuestion><p>I'm facing an enemy and no matter what I do I get Game Over</p></FaqQuestion>
            <FaqAnswer><p>It is possible you're out of your depth. Check your items and skills, and see if you get any ideas. There is almost always a way, if you get creative enough, to at least escape. That said, you may have charged into this situation a little under-prepared. Consider rewinding a few more actions back (<i className="fa-solid fa-backward"></i>), and taking a different course of action.</p></FaqAnswer>
        </Fragment>
    );
};
