import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";
import { ErrorCopy } from "copy/ErrorCopy";

export class UserProfileRetriever {

    public static async getUserProfile(): Promise<IUserProfile> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch("/api/v1/user/profile", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving user profile.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error(ErrorCopy.GetProfileError);
        }

        const model = await response.json() as IUserProfile;
        return model;
    }

}
