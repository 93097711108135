import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IPlaySetupState {
    preservedActionText?: string;
    setPreservedActionText: (text: string) => void;
    clearPreservedActionText: () => void;
}

export const usePlaySetupStore = create<IPlaySetupState>()(persist((set) => {
    return {
        preservedActionText: undefined,

        setPreservedActionText: (text: string) =>
            set(() => {
                return {
                    preservedActionText: text,
                };
            }),
        clearPreservedActionText: () =>
            set(() => ({
                preservedActionText: undefined,
            })),
    };
}, {
    name: "play-setup-data",
}));

