import { z } from "zod";
import { ContactCategory } from "./ContactCategory";
import { CommonSchemas } from "backend-models/app-api-models/validation/CommonSchemas";

export const SubjectMaxChars = 150;
export const MessageMaxChars = 50000;

export const ContactTicketSchema = z.object({
    category: z.enum(Object.values(ContactCategory) as [ContactCategory, ...ContactCategory[]]),
    email: CommonSchemas.EmailSchema,
    subject: z.string().min(1).max(SubjectMaxChars),
    message: z.string().min(1).max(MessageMaxChars),
});

export type IContactTicket = z.infer<typeof ContactTicketSchema>;
