import ContentLink from "components/ContentLink/ContentLink";
import "./ContactPage.css";
import Page from "components/Page/Page";
import ContactForm from "components/forms/ContactForm/ContactForm";

export default function ContactPage() {
    const pageId = "contact-page";

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingText="Contact Us"
            hasCloseButton={true}
        >
            <div className="ContactPage">
                <ContactForm />
                <div className="ContactPage-footer">
                    <p><span className="Sidebar-policy"><ContentLink text="Privacy Policy" path="/privacy-policy" /> | <ContentLink text="Terms of Use" path="/terms-of-use" /></span></p>
                    <p>EndlessGPT™ digital gameplay services</p>
                </div>
            </div>
        </Page >
    );
}
