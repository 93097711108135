import { ErrorCopy } from "copy/ErrorCopy";
import { ISubscribeRequest } from "backend-models/app-api-models/ISubscribeRequest";
import { SubmitValidationError } from "./SubmitValidationError";
import { SubscriptionSource } from "backend-models/services/mailing-list/mailing-list-api-models/SubscriptionSource";
import { IVerifySubscriptionRequest } from "backend-models/app-api-models/IVerifySubscriptionRequest";

export class SubscribeSubmitter {

    public static async subscribeUnauthed(email: string, captchaToken: string, source: SubscriptionSource) {

        const requestBody: ISubscribeRequest = {
            captchaToken,
            subscribe: {
                email,
                source,
            },
        };

        const response = await fetch("/api/v1/mailing-lists/subscribe-unauthed", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        if (response.status.toString().startsWith("4")) {
            console.error(ErrorCopy.FormValidationError);
            throw new SubmitValidationError(ErrorCopy.FormValidationError);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error(ErrorCopy.SubscribeUnauthedError);
        }
    }

    public static async putVerify(email: string, verifyToken: string): Promise<boolean> {

        const requestBody: IVerifySubscriptionRequest = {
            email,
            verifyToken,
        };

        const response = await fetch("/api/v1/mailing-lists/verify-subscription", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        return response.status.toString().startsWith("2");
    }

}
