import { create } from "zustand";

interface IInputHelpState {
    isOpen: boolean;
    openInputHelp: () => void;
    closeInputHelp: () => void;
}

export const useInputHelpStore = create<IInputHelpState>((set) => {

    return {
        isOpen: false,
        openInputHelp: () =>
            set(() => {
                return {
                    isOpen: true,
                };
            }),
        closeInputHelp: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

