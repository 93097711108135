import { useState, MouseEvent } from "react";
import "./NewGameButton.css";
import debounce from "lodash.debounce";

export enum NewGameButtonStates {
    Ready = "Ready",
    Creating = "Loading",
    Error = "Error",
}

interface IProps {
    onPressed: () => void;
    buttonState: NewGameButtonStates;
}

export default function NewGameButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    const buttonTips = {
        [NewGameButtonStates.Ready]: "Play this game.",
        [NewGameButtonStates.Creating]: "Creating..",
        [NewGameButtonStates.Error]: "There was an error creating the game. Click to retry.",
    };
    const buttonTip = buttonTips[props.buttonState];

    const iconClasses = {
        [NewGameButtonStates.Ready]: "fa-solid fa-wand-sparkles",
        [NewGameButtonStates.Creating]: "fa-solid fa-hourglass-start",
        [NewGameButtonStates.Error]: "fa-solid fa-circle-exclamation red",
    };
    const iconClass = iconClasses[props.buttonState];

    return (
        <button
            className={isDepressed ? "NewGameButton NewGameButton-clicked" : "NewGameButton"}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title={buttonTip}
        >
            <i className={iconClass}></i>
        </button>
    );
};
