import Modal from "react-modal";
import { useGameOverHelpStore } from "./GameOverHelpStore";
import { useNavigate } from "react-router-dom";
import CloseButton from "components/CloseButton/CloseButton";
import HelpSection from "components/Help/HelpSection/HelpSection";
import DeathFaqs from "components/Help/Faq/DeathFaqs/DeathFaqs";

Modal.setAppElement("#root");

export default function GameOverHelpModal() {
    const { isOpen, closeGameOverHelp } = useGameOverHelpStore();
    const navigate = useNavigate();

    const handleCancel = () => {
        closeGameOverHelp();
    };

    const handleMoreHelp = () => {
        closeGameOverHelp();
        navigate("/game-manual");
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCancel}
            contentLabel="Quick Help"
            className="InputHelpModal Modal"
        >
            <div className="Modal-content">
                <CloseButton onPressed={handleCancel} />
                <div className="ScrollableModal-content">
                    <div className="ScrollableModal-inner">
                        <h2>Game Over</h2>
                        <p className="ScrollableModal-button-para"><button onClick={handleMoreHelp}>More Help</button></p>
                        <HelpSection>
                            <DeathFaqs />
                        </HelpSection>
                        <p className="ScrollableModal-button-para"><button onClick={handleMoreHelp}>More Help</button></p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
