import { ReactNode, MouseEvent } from "react";
import "./ChatBubble.css";
import { ChatViewType } from "components/Chat/ChatViewType";

interface IProps {
  children: ReactNode;
  id?: string,
  viewType: ChatViewType;
  onBubbleClicked?: () => void;
  isHidden?: boolean;
  hasOuterBubble?: boolean;
}

export default function ChatBubble(props: IProps) {

  const frameClassNames = `ChatBubble-frame ChatBubble-frame-${props.viewType}`;

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!!props.onBubbleClicked) {
      props.onBubbleClicked();
    }
  };

  const optionalIdAttr = props.id ? { id: props.id } : {};

  return (
    <div
      {...optionalIdAttr}
      className="ChatBubble"
      style={props.isHidden ? { display: "none" } : {}}
      onClick={onClick}
    >
      <div className={frameClassNames}>
        {props.children}
      </div>
    </div>
  );
}

