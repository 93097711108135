import { create } from "zustand";

interface IChatInputState {
    inputText: string;
    setInputText: (inputText: string) => void;
}

export const chatInputStore = create<IChatInputState>(set => ({
    inputText: "",
    setInputText: (inputText: string) => set(() => ({ inputText: inputText })),
}));
