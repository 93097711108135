import "./DiceButton.css";
import { useState, MouseEvent } from "react";

interface IProps {
    onClick: () => {};
}

export default function DiceButton(props: IProps) {
    const [image, setImage] = useState("1d20-20.gif");
    const [animationPlayed, setAnimationPlayed] = useState(false);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (!animationPlayed) {
            setAnimationPlayed(true);
            setImage("1d20.gif");
            setTimeout(() => {
                setImage("1d20-19.gif");
                setTimeout(() => {
                    props.onClick();
                }, 1500);
            }, 1300);
        }
    };

    return (
        <button className="DiceButton" onClick={handleClick} aria-label="Captcha check button">
            <img src={"1d20/" + image} alt="Dice roll" />
        </button>
    );
}
