import { create } from "zustand";

interface ICaptchaModalState {
    isOpen: boolean;
    onCompleted: (captchaToken: string) => void;
    onCancel: () => void;
    performCaptcha: (
        completedCallback: (captchaToken: string) => void,
        cancelCallback: () => void) => void;
    closeCaptcha: () => void;
}

export const useCaptchaModalStore = create<ICaptchaModalState>((set) => {

    return {
        isOpen: false,
        gameToSaveFirst: null,
        onCompleted: () => { },
        onCancel: () => { },
        performCaptcha: (completedCallback, cancelCallback) =>
            set(() => {
                return {
                    isOpen: true,
                    onCompleted: completedCallback,
                    onCancel: cancelCallback,
                };
            }),
        closeCaptcha: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

