import { create } from "zustand";

interface IGameOverHelpState {
    isOpen: boolean;
    openGameOverHelp: () => void;
    closeGameOverHelp: () => void;
}

export const useGameOverHelpStore = create<IGameOverHelpState>((set) => {

    return {
        isOpen: false,
        openGameOverHelp: () =>
            set(() => {
                return {
                    isOpen: true,
                };
            }),
        closeGameOverHelp: () =>
            set(() => ({
                isOpen: false,
            })),
    };
});

