import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="NotFound">
      <h1><span className="fa fa-fw fa-chain-broken"></span></h1>
      <h1>Not Found</h1>
      <p>This may have been removed by the creator.</p>
    </div>
  );
}

