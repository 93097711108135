import { ISavesForJourney } from "backend-models/services/saves/saves-api-models/ISavesForJourney";
import LoadableGame from "../LoadableGame/LoadableGame";
import "./JourneyLoadBubble.css";

interface IProps {
    savesForJouney: ISavesForJourney;
}

export default function JourneyLoadBubble(props: IProps) {

    return (
        <div
            className="JourneyLoadBubble"
        >
            <div className="JourneyLoadBubble-frame">
                {props.savesForJouney.saves.map(save => (
                    <LoadableGame key={save.turnLoc.turnId} save={save} />
                ))
                }
            </div>
        </div>
    );
};
