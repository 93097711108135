import { useAuth0 } from "@auth0/auth0-react";
import Page from "components/Page/Page";
import { AuthError } from "auth/AuthError";
import { UserProfileRetriever } from "./UserProfileRetriever";
import { authStore } from "auth/AuthStore";

export default function AccountPage() {
    const { user, logout, loginWithRedirect } = useAuth0();
    const { userProfile, setUserProfile } = authStore();

    const getUserProfile = async () => {
        try {
            const profile = await UserProfileRetriever.getUserProfile();
            setUserProfile(profile);
        } catch (e) {
            if (e instanceof AuthError) {
                loginWithRedirect(
                    { appState: { returnTo: window.location.pathname } });
            } else {
                console.error(e);
            }
        }
    };


    return (
        <Page
            id="account-page"
            headingText="Account"
            headingIconClass="fa fa-fw fa-user"
            needsAuth={true}
            onLoginConfirmed={getUserProfile}
            isChildrenLoading={!userProfile}
            hasCloseButton={true}
        >
            <h2><button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                <span className="fa fa-fw fa-sign-out" /><span> Log out</span>
            </button><span> {user?.nickname}</span>
            </h2>
            <table>
                <tbody>
                    <tr><th>Token Balance:</th><td>{userProfile?.tokenBalance}</td></tr>
                </tbody>
            </table>
        </Page>
    );
}
