import { LazyPicSize } from "./LazyPicSize";

interface IProps {
    size: LazyPicSize;
}

export default function LazyPicturePlaceholder(props: IProps) {

    const pixels = props.size === LazyPicSize.Small ? 64 : 256;
    const pixelStr = pixels.toString();
    const pixelsAnimStr = (pixels - 2).toString();

    return (
        <svg
            width={pixelStr}
            height={pixelStr}
            viewBox={`0 0 ${pixelStr} ${pixelStr}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g className="LazyPicture-swirly-animation">
                <rect
                    x="1"
                    y="1"
                    width={pixelsAnimStr}
                    height={pixelsAnimStr}
                    stroke="gray"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="4 4"
                />
            </g>
        </svg>
    );
};
