import { useEffect, useState } from "react";
import "./FlashingCursor.css";
import * as R from "ramda";
import delay from "delay-async";

interface IProps {
    useWaitText?: boolean;
}

export default function FlashingCursor(props: IProps) {

    const [waitText, setWaitText] = useState("");

    useEffect(() => {
        const addText = async () => {
            await delay(13000);
            const textOptions = [
                "Updating world state",
                "Thickening plot",
                "The world spins",
                "Elsewhere, events unfold",
                "Scenes shift",
                "Distant dramas develop",
                "Plot lines advance",
                "Narratives progress",
            ];
            const text = R.nth(Math.floor(Math.random() * textOptions.length), textOptions);
            const textElips = text + " ⏳..";
            let textSoFar = "";
            for (const c of R.reverse(textElips)) {
                textSoFar = c + textSoFar;
                setWaitText(textSoFar);
                await delay(30);
            }
        };
        if (!props.useWaitText) {
            return;
        }
        addText();
    }, [setWaitText, props.useWaitText]);


    return (
        <div className="FlashingCursor">
            {props.useWaitText && <div className="FlashingCursor-text">{waitText && <span>{waitText}</span>}</div>}
            <div className="FlashingCursor-square"></div>
        </div>
    );
};
