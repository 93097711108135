import { ISaveRequest } from "backend-models/app-api-models/ISaveRequest";
import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { IAutosaveRequest } from "backend-models/app-api-models/IAutosaveRequest";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";

export class GameSaver {

    public static async save(
        gameId: string, turnLoc: ITurnLocation, picId: string | undefined, sceneText: string
    ) {
        const saveName = sceneText.substring(0, 60) + "..";
        const beginReq: ISaveRequest = {
            gameId,
            historyId: turnLoc.historyId,
            saveName,
            picId,
        };
        const accessToken = authStore.getState().accessToken;
        const response = await fetch(
            `/api/v1/journies/${turnLoc.journeyId}/turns/${turnLoc.turnId}/save`,
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(beginReq)
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when saving game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ beginReq });
            console.error({ response });
            throw Error("Error when attempting to save game.");
        }
    }

    public static async autosave(
        gameId: string, turnLoc: ITurnLocation
    ) {
        const beginReq: IAutosaveRequest = {
            gameId,
            historyId: turnLoc.historyId,
        };
        const accessToken = authStore.getState().accessToken;
        const response = await fetch(
            `/api/v1/journies/${turnLoc.journeyId}/turns/${turnLoc.turnId}/autosave`,
            {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(beginReq)
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when autosaving game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ beginReq });
            console.error({ response });
            throw Error("Error when attempting to autosave game.");
        }
    }

}
