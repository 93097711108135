import { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "./LazyPicture.css";
import { authStore } from "auth/AuthStore";
import LazyPicturePlaceholder from "./LazyPicturePlaceholder";
import { LazyPicSize } from "./LazyPicSize";

interface IProps {
    size: LazyPicSize;
    pictureId?: string | null;
    imgUrlOverride?: string | null;
    removeBottomMargin?: boolean;
}

export default function LazyPicture(props: IProps) {
    const { accessToken } = authStore();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const imgRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    let imgClassName = "";
    if (isLoading) {
        imgClassName += "LazyPicture-hidden";
    }
    if (props.size === LazyPicSize.Small) {
        if (imgClassName.length) {
            imgClassName += " ";
        }
        imgClassName += "LazyPicture-small";
    } else if (props.size === LazyPicSize.NewGameCover) {
        if (imgClassName.length) {
            imgClassName += " ";
        }
        imgClassName += "LazyPicture-new-game-cover";
    }

    useEffect(() => {
        if (inView) {
            if (props.imgUrlOverride) {
                setImgSrc(props.imgUrlOverride);
            } else {
                if (!accessToken) {
                    return;
                }
                const fetchImageWithHeaders = async () => {
                    try {
                        const imageUrl = `/api/v1/pictures/${props.pictureId}`;
                        const response = await fetch(
                            imageUrl, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                        if (!response.ok) {
                            console.error("Error fetching the image: ", imageUrl);
                            return;
                        }

                        const imgBlob = await response.blob();
                        const imgObjUrl = URL.createObjectURL(imgBlob);

                        if (!!imgRef?.current) {
                            const imgElem = imgRef.current as any;
                            imgElem.onload = () => {
                                setIsLoading(false);
                                URL.revokeObjectURL(imgObjUrl);
                            };
                            setImgSrc(imgObjUrl);
                        }
                    } catch (error) {
                        console.error("Error fetching the image:", error);
                    }
                };
                fetchImageWithHeaders();
            }
        }
    }, [inView, props.pictureId, props.imgUrlOverride, accessToken]);

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    const mainClassName = "LazyPicture" + (props.removeBottomMargin ?
        " LazyPicture-no-bottom-margin" :
        "");

    return (
        <div className={mainClassName} ref={ref}>
            {isLoading && <LazyPicturePlaceholder size={props.size} />}
            <img
                {...(imgClassName.length ? { className: imgClassName } : {})}
                ref={imgRef}
                src={props.imgUrlOverride || imgSrc}
                onLoad={handleImageLoad}
                alt="AI-generated game scene"
            />
        </div>
    );
};
