import { BuiltInGameId } from "backend-models/services/built-in-games/built-in-games-api-models/BuiltInGameId";
import NewableGame from "../NewableGame/NewableGame";
import "./NewGameBubble.css";

export default function NewGameBubble() {

    return (
        <div
            className="NewGameBubble"
        >
            <div className="NewGameBubble-frame">
                <NewableGame
                    gameId={BuiltInGameId.Mithgard35}
                    text="Unravel sinister plots in this dark fantasy world, beginning in the grand city of Mithgard"
                    imgUrl="mithgard_cover_art.webp"
                />
                <NewableGame
                    gameId=""
                    text="More games coming soon! Subscribe for updates.."
                    imgUrl="EndessGPT_Logo_256.webp"
                    isSubscribe={true}
                />
            </div>
        </div>
    );
};
