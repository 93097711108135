import { useState, MouseEvent } from "react";
import "./SubmitButton.css";
import debounce from "lodash.debounce";

export enum SubmitButtonStates {
    Ready = "Ready",
    Submitting = "Submitting",
    Submitted = "Submitted",
    Error = "Error",
}

interface IProps {
    onPressed: () => void;
    buttonState: SubmitButtonStates;
    text?: string;
    submittedText?: string;
}

export default function SubmitButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);
    const buttonText =
        (props.buttonState === SubmitButtonStates.Submitted ? props.submittedText : null)
        || props.text
        || "Submit";

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        if (props.buttonState === SubmitButtonStates.Submitted || props.buttonState === SubmitButtonStates.Submitting) {
            return;
        }
        props.onPressed();
    }, 500);

    const buttonTips = {
        [SubmitButtonStates.Ready]: "Click to " + buttonText,
        [SubmitButtonStates.Submitting]: "Submitting form",
        [SubmitButtonStates.Submitted]: "Form submitted successfully.",
        [SubmitButtonStates.Error]: "Error submitting the form. Click to retry.",
    };
    const buttonTip = buttonTips[props.buttonState];

    const iconClasses = {
        [SubmitButtonStates.Ready]: "",
        [SubmitButtonStates.Submitting]: "fa-solid fa-hourglass-start",
        [SubmitButtonStates.Submitted]: "fa-regular fa-thumbs-up",
        [SubmitButtonStates.Error]: "fa-solid fa-circle-exclamation red",
    };
    const iconClass = iconClasses[props.buttonState];

    return (
        <button
            className={
                "SubmitButton" +
                (props.buttonState === SubmitButtonStates.Submitted ? " SubmitButton-submitted" : "") +
                (isDepressed &&
                    props.buttonState !== SubmitButtonStates.Submitted &&
                    props.buttonState !== SubmitButtonStates.Submitting ? " SubmitButton-clicked" : "") +
                (props.buttonState === SubmitButtonStates.Submitting ? " SubmitButton-submitting" : "")
            }
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title={buttonTip}
        >
            {props.buttonState !== SubmitButtonStates.Ready && <i className={iconClass}></i>} {buttonText}
        </button>
    );
};
