import { Fragment, useEffect, useState } from "react";
import "./MailingListVerifyPage.css";
import Page from "components/Page/Page";
import { SubscribeSubmitter } from "components/forms/SubscribeSubmitter";
import { useNavigate, useSearchParams } from "react-router-dom";
import MailingListSubscribe from "components/MailingListSubscribe/MailingListSubscribe";
import { SubscriptionSource } from "backend-models/services/mailing-list/mailing-list-api-models/SubscriptionSource";

export default function MailingListVerifyPage() {
    const pageId = "mailing-list-verify-page";

    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [wasVerifySuccessful, setWasVerifySuccessful] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const getUserProfile = async () => {
            if (!isLoading) {
                return;
            }

            try {
                const email = searchParams.get("email") || "";
                const token = searchParams.get("token") || "";
                const isVerified = await SubscribeSubmitter.putVerify(email, token);
                setWasVerifySuccessful(isVerified);
            } catch (e) {
                setWasVerifySuccessful(false);
            }
            setIsLoading(false);
        };

        getUserProfile();
    }, [isLoading, searchParams]);


    return (
        <Page
            id={pageId}
            needsAuth={false}
            collapsePadding={true}
            headingText="Mailing List Verify"
            hasCloseButton={true}
            isChildrenLoading={isLoading}
        >
            <div className="MailingListVerifyPage">
                {wasVerifySuccessful &&
                    <Fragment>
                        <h2>Successfully verified. Exciting news is coming your way soon!</h2>
                        <img
                            className="MailingListVerifyPage-logo"
                            alt="EndlessGPT Logo"
                            title="Go play!"
                            src="EndessGPT_Logo_256.webp"
                            onClick={
                                () => {
                                    navigate("/");
                                }}
                        />
                        <h3>EndlessGPT™</h3>
                        <p><i>Endless adventure. Endless possibilities.</i></p>
                    </Fragment>
                }
                {(!wasVerifySuccessful) &&
                    <Fragment>
                        <p>Sorry, something went wrong processing that validation link. It may have expired or already been used. You may send a fresh link below.</p>
                        <MailingListSubscribe
                            initialEmail={searchParams.get("email") || ""}
                            source={SubscriptionSource.MailingListVerify}
                        />
                    </Fragment>
                }
            </div>
        </Page >
    );
}
