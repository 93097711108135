import { ErrorCopy } from "copy/ErrorCopy";
import { Fragment } from "react";

export default function PolicyWarning() {

    return (
        <Fragment>
            <h2><i className="fa-solid fa-triangle-exclamation"></i> Warning</h2>
            <p>EndlessGPT is intended for grown-up audiences. While it is designed to be "Safe For Work", it is partially AI-generated, so the content cannot be entirely predicted. Minors playing must be supervised by a parent or guardian.</p>
            <p><b>Your gameplay actions on EndlessGPT are not private.</b> Using certain sharing features will cause your journey to be shared on the Internet. <b>You do not own your gameplay content on EndlessGPT.</b> Your gameplay may be viewed and used by EndlessGPT staff for diagnostic, training, investigative or service improvement purposes. <b>Never enter sensitive or personally identifiable information into game actions</b>.</p>
            <p>If your turns cause explicit or ultraviolent content to be generated which triggers our content blockers too often, it may result in {ErrorCopy.AccountActionWarning}</p>
        </Fragment>
    );
};
