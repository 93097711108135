import "./RecommendedAction.css";
import { chatInputStore } from "components/Chat/ChatInput/ChatInputStore";

interface IProps {
  text: string;
}

export default function RecommendedAction(props: IProps) {
  const { setInputText } = chatInputStore();

  return (
    <div
      className="RecommendedAction"
      onClick={() => setInputText(props.text)}
    >{props.text}</div>
  );
}

