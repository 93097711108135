import { useState, MouseEvent } from "react";
import "./CloseButton.css";
import debounce from "lodash.debounce";

interface IProps {
    onPressed: () => void;
}

export default function CloseButton(props: IProps) {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        props.onPressed();
    }, 500);

    return (
        <button
            className={isDepressed ? "CloseButton CloseButton-clicked" : "CloseButton"}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title="Close"
        >
            <i className="fa-solid fa-xmark"></i>
        </button>
    );
};
