import { BuiltInOpeningPics } from "components/LazyPicture/BuiltInOpeningPics";
import { BuiltInOpeningTexts } from "backend-models/services/built-in-games/built-in-games-api-models/BuiltInOpeningTexts";
import { IGameInProgressView } from "backend-models/services/games/games-api-models/IGameInProgressView";
import * as R from "ramda";
import { ISceneView } from "backend-models/services/journies/journies-api-models/ISceneView";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";

export class OpeningResolver {
    public static resolve(
        game: IGameInProgressView, history: ITurnView[]
    ): IOpeningView | null {

        const shouldAddOpening =
            R.isEmpty(history) ||
            history[0].isFirstTurn;

        if (!shouldAddOpening) {
            return null;
        }

        const opening = this.getOpeningScene(game);
        if (!opening) {
            return null;
        }

        const imgOverride = shouldAddOpening ? this.getOpeningImgOverride(game) : null;

        const view: IOpeningView = {
            scene: opening,
            picId: opening.picId,
            imgUrlOverride: imgOverride
        };
        return view;
    }

    private static getOpeningScene(game: IGameInProgressView) {
        if (game.builtInGameId) {
            const openingScene: ISceneView = {
                sceneId: "0",
                text: BuiltInOpeningTexts[game.builtInGameId],
                isCanon: true,
            };
            return openingScene;
        } else {
            // TODO support non-built-in games
            return null;
        }
    };

    private static getOpeningImgOverride(game: IGameInProgressView) {
        if (game.builtInGameId) {
            return BuiltInOpeningPics[game.builtInGameId];
        } else {
            return null;
        }
    };

}

interface IOpeningView {
    scene: ISceneView;
    picId?: string;
    imgUrlOverride: string | null;
}
