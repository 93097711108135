import NewGameBubble from "components/NewGame/NewGameBubble/NewGameBubble";
import Page from "components/Page/Page";

export default function NewGamePage() {
    const pageId = "new-game-page";

    return (
        <Page
            id={pageId}
            collapsePadding={true}
            needsAuth={false}
            headingIconClass="fa-solid fa-wand-sparkles"
            headingText="New Game"
            hasCloseButton={true}
        >
            <NewGameBubble />
        </Page>
    );
}
