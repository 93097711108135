import { Fragment } from "react";
import "./ChatText.css";

interface IProps {
  text: string;
  secondaryText?: string;
  subText?: string;
  isDeath?: boolean;
}

export default function ChatText(props: IProps) {

  return (
    <div className="ChatText">{props.text}{props.secondaryText}{props.isDeath && <Fragment><br />&nbsp;<br />To rewind, on an earlier scene press:<i className="fa-solid fa-backward ChatText-icon"></i><br />&nbsp;<br /></Fragment>}{props.subText &&
      <Fragment>
        <span className="ChatText-subtext"><br />&nbsp;<br />{props.subText}</span>
      </Fragment>
    }</div>
  );
}

