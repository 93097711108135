import { useState, MouseEvent } from "react";
import "./LoadButton.css";
import debounce from "lodash.debounce";

export enum LoadButtonStates {
    Ready = "Ready",
    Loading = "Loading",
    Error = "Error",
}

interface IProps {
    onPressed: () => void;
    buttonState: LoadButtonStates;
}

const LoadButton: React.FC<IProps> = ({ onPressed, buttonState: saveState }) => {
    const [isDepressed, setIsDepressed] = useState(false);

    const depressButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsDepressed(true);
    };

    const releaseButton = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const touchDownButton = () => {
        setIsDepressed(true);
    };

    const touchUpButton = () => {
        setTimeout(() => setIsDepressed(false), 200);
        debouncedOnPressed();
    };

    const debouncedOnPressed = debounce(() => {
        onPressed();
    }, 500);

    const buttonTips = {
        [LoadButtonStates.Ready]: "Load this saved game.",
        [LoadButtonStates.Loading]: "Loading..",
        [LoadButtonStates.Error]: "There was an error loading the game. Click to retry.",
    };
    const buttonTip = buttonTips[saveState];

    const iconClasses = {
        [LoadButtonStates.Ready]: "fa-solid fa-upload",
        [LoadButtonStates.Loading]: "fa-solid fa-hourglass-start",
        [LoadButtonStates.Error]: "fa-solid fa-circle-exclamation red",
    };
    const iconClass = iconClasses[saveState];

    return (
        <button
            className={isDepressed ? "LoadButton LoadButton-clicked" : "LoadButton"}
            onClick={(e) => { e.stopPropagation(); }}
            onMouseDown={depressButton}
            onMouseUp={releaseButton}
            onTouchStart={touchDownButton}
            onTouchEnd={touchUpButton}
            onTouchCancel={touchUpButton}
            title={buttonTip}
        >
            <i className={iconClass}></i>
        </button>
    );
};

export default LoadButton;
