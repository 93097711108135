import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import { ChatViewType } from "components/Chat/ChatViewType";
import { IActionView } from "backend-models/services/journies/journies-api-models/IActionView";

interface IProps {
    id?: string;
    action: IActionView;
}

export default function ChatAction(props: IProps) {
    return (
        <ChatBubble id={props.id} viewType={ChatViewType.Action} >
            <ChatPreContainer>
                <ChatPre viewType={ChatViewType.Action}>
                    <ChatText text={props.action.text}></ChatText>
                </ChatPre>
            </ChatPreContainer>
        </ChatBubble >
    );
}
