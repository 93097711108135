import "./FormMessages.css";
import { Fragment } from "react";
import * as R from "ramda";

interface IProps {
    message?: string;
    failureReasons?: string[];
}

export default function FormMessages(props: IProps) {
    const reasons = props.failureReasons || [];
    const isFailure = !R.isEmpty(reasons);
    return (
        <Fragment>
            {(isFailure || !!props.message) &&
                <div className={"FormMessages" + (isFailure ? " FormMessages-failure" : "")}>
                    {!!props.message &&
                        <span>{props.message}</span>
                    }
                    {isFailure &&
                        <ul className="FormMessages-list">
                            {reasons.map((reason, i) => (
                                <li key={i} className="FormMessages-item">{reason}</li>
                            ))}
                        </ul>
                    }
                </div>
            }
        </Fragment>
    );
}
