import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";
import { ITurnLocation } from "backend-models/services/saves/saves-api-models/ITurnLocation";

export class MoreHistoryRetriever {

    public static async loadMoreHistory(earliestTurnLoc: ITurnLocation): Promise<ITurnView[]> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(
            `/api/v1/journies/${earliestTurnLoc.journeyId}/turns/${earliestTurnLoc.turnId}/previous?historyId=${earliestTurnLoc.historyId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving more history.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response, earliestTurnLoc });
            throw Error("Error when retrieving more history.");
        }

        const model = await response.json() as ITurnView[];
        return model;
    }

}
