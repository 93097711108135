import "./MailingListSubscribe.css";
import { SubscriptionSource } from "backend-models/services/mailing-list/mailing-list-api-models/SubscriptionSource";
import { useCaptchaModalStore } from "components/Captcha/CaptchaModal/CaptchaModalStore";
import FormMessages from "components/forms/FormMessages/FormMessages";
import { SubscribeSubmitter } from "components/forms/SubscribeSubmitter";
import TextInput from "components/forms/TextInput/TextInput";
import SubmitButton, { SubmitButtonStates } from "components/forms/SubmitButton/SubmitButton";
import { ErrorCopy } from "copy/ErrorCopy";
import { useEffect, useState } from "react";
import { Validor } from "backend-models/app-api-models/validation/Validor";
import { CommonSchemas, EmailMaxChars } from "backend-models/app-api-models/validation/CommonSchemas";
import { IValidity } from "backend-models/app-api-models/validation/IValidity";
import { UserProfileRetriever } from "pages/AccountPage/UserProfileRetriever";
import { useAuth0 } from "@auth0/auth0-react";

interface IProps {
    initialEmail?: string;
    source: SubscriptionSource;
}

export default function MailingListSubscribe(props: IProps) {
    const { isAuthenticated, isLoading } = useAuth0();
    const { performCaptcha } = useCaptchaModalStore();
    const [subscribeButtonState, setSubscribeButtonState] = useState(SubmitButtonStates.Ready);
    const [subscribeEmail, setSubscribeEmail] = useState("");
    const [subscribeEmailValidity, setSubscribeEmailValidity] = useState<IValidity>();
    const [subscribeEmailForceShowInvalid, setSubscribeEmailForceShowInvalid] = useState(false);
    const [displayedReasons, setDisplayedReasons] = useState<string[]>([]);
    const [successMsg, setSuccessMsg] = useState("");
    const [emailPrefill, setEmailPrefill] = useState<string | undefined>(undefined);


    const subscribe = async () => {
        if (subscribeEmailValidity?.isValid) {
            setDisplayedReasons([]);
            setSubscribeButtonState(SubmitButtonStates.Submitting);

            performCaptcha(

                // Captcha completed
                async (captchaToken: string) => {
                    console.log("Captcha completed");
                    try {
                        await SubscribeSubmitter.subscribeUnauthed(subscribeEmail, captchaToken, props.source);
                        setSubscribeButtonState(SubmitButtonStates.Submitted);
                        setSuccessMsg("Please click the link in your email to confirm your subscription. If you don't see it after a few moments, please check your spam folder.");
                    }
                    catch (error) {
                        setSubscribeButtonState(SubmitButtonStates.Error);
                        setDisplayedReasons([ErrorCopy.UnknownServerError]);
                    }
                },

                // Captcha cancelled
                () => {
                    setSubscribeButtonState(SubmitButtonStates.Error);
                    setDisplayedReasons([ErrorCopy.CaptchaFailed]);
                    console.log("Aborted captcha");
                },
            );

        } else {
            setDisplayedReasons(subscribeEmailValidity?.failureReasons as string[]);
            setSubscribeEmailForceShowInvalid(true);
        }
    };

    const onSubscribeEmailChange = (val: string, validity: IValidity) => {
        setSubscribeEmail(val);
        setSubscribeEmailValidity(validity);
        setSubscribeEmailForceShowInvalid(false);
    };


    useEffect(() => {
        const processLoginStatus = async () => {
            if ((!isLoading) && isAuthenticated) {
                try {
                    const profile = await UserProfileRetriever.getUserProfile();
                    setEmailPrefill(profile.email);
                } catch (e) {
                    console.error("Failed to laod profile to pre-populate mailing list subscribe form");
                    console.error(e);
                }

            }
        };
        processLoginStatus();
    }, [isLoading, isAuthenticated]);

    const emailInitialVal = props.initialEmail ?? emailPrefill ?? undefined;

    return (
        <div className="MailingListSubscribe">
            <div>
                <TextInput
                    onChanged={onSubscribeEmailChange}
                    maxLength={EmailMaxChars}
                    validityTest={(val) => {
                        return Validor.getIsValidField(CommonSchemas.EmailSchema, val, "Email address");
                    }}
                    forceShowInvalid={subscribeEmailForceShowInvalid}
                    placeholderText="Enter email address"
                    onEnter={subscribe}
                    initialVal={emailInitialVal}
                />
            </div>
            <div className="MailingListSubscribe-messages">
                <FormMessages failureReasons={displayedReasons} message={successMsg} />
            </div>
            <div>
                <SubmitButton
                    text="Subscribe"
                    submittedText="Submitted"
                    onPressed={subscribe}
                    buttonState={subscribeButtonState}
                />
            </div>
        </div>
    );
}
