import { IUserProfile } from "backend-models/services/users/users-api-models/IUserProfile";
import { create } from "zustand";

interface IAuthState {
    accessToken: string | null;
    setToken: (accessToken: string) => void;
    userProfile: IUserProfile | null;
    setUserProfile: (userProfile: IUserProfile) => void;
    setTokenBalance: (updatedBalance: number) => void;
}

export const authStore = create<IAuthState>(set => ({
    accessToken: null,
    userProfile: null,
    setToken: (accessToken: string) => set(() => ({ accessToken: accessToken })),
    setUserProfile: (userProfile: IUserProfile) => set(() => ({ userProfile: userProfile })),
    setTokenBalance: (updatedBalance: number) => set((state) => ({
        userProfile: {
            ...state.userProfile as IUserProfile,
            ...{
                tokenBalance: updatedBalance,
            },
        },
    })),
}));
