import { IJourneyView } from "backend-models/services/journies/journies-api-models/IJourneyView";
import { create } from "zustand";
import { IGameInProgressView } from "backend-models/services/games/games-api-models/IGameInProgressView";
import * as R from "ramda";
import { IActionView } from "backend-models/services/journies/journies-api-models/IActionView";
import { ISceneView } from "backend-models/services/journies/journies-api-models/ISceneView";
import { ITurnView } from "backend-models/services/journies/journies-api-models/ITurnView";

interface IPlayState {
    initJourney: IJourneyView | null;

    game: IGameInProgressView | null;
    history: ITurnView[];
    latestTurn: ITurnView | null;
    latestAction: IActionView | null;
    latestScene: ISceneView | undefined;
    journeyId: string | undefined;
    currHistoryId: string | undefined;
    newScrollId: string | null;

    initialize: (journeyView: IJourneyView) => void;
    setHistory: (history: ITurnView[]) => void;
    setLatestTurn: (latestTurn: ITurnView) => void;
    setLatestAction: (latestAction: IActionView) => void;
    setLatestScene: (latestScene: ISceneView) => void;
    setJourneyId: (journeyId: string | undefined) => void;
    setCurrHistoryId: (currHistoryId: string | undefined) => void;
    clearNewScrollId: () => void;
    revertHistory: (truncatedHistory: ITurnView[], latestTurn: ITurnView) => void;
    prependHistory: (earlierHistory: ITurnView[]) => void;
}

export const playStore = create<IPlayState>(set => ({
    initJourney: null,
    game: null,
    history: [],
    latestTurn: null,
    latestAction: null,
    latestScene: undefined,
    journeyId: undefined,
    currHistoryId: undefined,
    newScrollId: null,

    initialize: (journeyView: IJourneyView) => set(() => {
        const latestTurn = R.last(journeyView.history);
        return {
            initJourney: journeyView,
            game: journeyView.game,
            history: R.init(journeyView.history),
            latestTurn,
            latestAction: latestTurn?.action,
            latestScene: latestTurn?.scene,
            journeyId: journeyView.journeyId,
            currHistoryId: journeyView.currentHistoryId,
        };
    }),

    setHistory: (history: ITurnView[]) => set(() => ({ history })),
    setLatestAction: (latestAction: IActionView) => set(() => ({ latestAction })),
    setLatestScene: (latestScene: ISceneView) => set(() => ({ latestScene })),
    setJourneyId: (journeyId: string | undefined) => set(() => ({ journeyId })),
    setCurrHistoryId: (currHistoryId: string | undefined) => set(() => ({ currHistoryId })),
    clearNewScrollId: () => set(() => ({ newScrollId: null })),
    setLatestTurn: (latestTurn: ITurnView) => set(() => ({ latestTurn })),
    revertHistory: (truncatedHistory: ITurnView[], latestTurn: ITurnView) => set(() => ({
        history: truncatedHistory,
        latestTurn,
        latestAction: latestTurn.action,
        latestScene: latestTurn.scene,
        currHistoryId: latestTurn.historyId,
    })),
    prependHistory: (earlierHistory: ITurnView[]) => set((state) => {
        const newScrollId = (R.last(earlierHistory) as ITurnView).scene.sceneId;
        return {
            history: earlierHistory.concat(state.history),
            newScrollId,
        };
    }),

}));
