import "./LoadPage.css";
import { SavesRetreiver } from "components/LoadSaves/SavesRetreiver";
import Page from "components/Page/Page";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import ReactPaginate from "react-paginate";
import JourneyLoadBubble from "components/LoadSaves/JourneyLoadBubble/JourneyLoadBubble";
import * as R from "ramda";
import { ISavesByGameId } from "backend-models/services/saves/saves-api-models/ISavesByGameId";
import { ISavesForJourney } from "backend-models/services/saves/saves-api-models/ISavesForJourney";

export default function LoadPage() {
    const { loginWithRedirect } = useAuth0();

    const [savesForGame, setSavesForGame] = useState<ISavesByGameId | null>(null);
    const [currentPageSbjs, setCurrentPageSbjs] = useState<ISavesForJourney[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemCount, setItemCount] = useState(0);
    const [currPage, setCurrPage] = useState(0);
    const itemsPerPage = 10;

    const onLoginConfirmed = () => {
        const getSaves = async () => {
            try {
                const newSavesForGame = await SavesRetreiver.getSavesForGame();
                setSavesForGame(newSavesForGame);

                const sbjsForPage = getSavesByJourneyWithLimit(
                    newSavesForGame.savesByJourney, 0, itemsPerPage);
                setCurrentPageSbjs(sbjsForPage);
                const newItemCount = R.sum(R.flatten(
                    newSavesForGame.savesByJourney.map(sbj => sbj.saves.length)));
                setItemCount(newItemCount);
                const pageCount = Math.ceil(newItemCount / itemsPerPage);
                setPageCount(pageCount);

            } catch (e) {
                if (e instanceof AuthError) {
                    loginWithRedirect(
                        { appState: { returnTo: window.location.pathname } });
                }
                else {
                    console.error(e);
                }
            }
        };
        getSaves();
    };

    const handlePageClick = (selectedItem: { selected: number; }) => {
        const newStartIndex = (selectedItem.selected * itemsPerPage);// % itemCount;
        const sbjsForPage = getSavesByJourneyWithLimit(
            (savesForGame as ISavesByGameId).savesByJourney, newStartIndex, itemsPerPage);
        setCurrentPageSbjs(sbjsForPage);
        setCurrPage(selectedItem.selected);
    };

    const getSavesByJourneyWithLimit = (
        savesByJourney: ISavesForJourney[], startIndex: number, maxCount: number
    ) => {
        if (!savesByJourney || R.isEmpty(savesByJourney)) {
            return [];
        }
        let currIndex = -1;
        let soFarCount = 0;
        const limitedSavesByJourney: ISavesForJourney[] = [];
        let currSbj: ISavesForJourney;
        for (const sbj of savesByJourney) {
            currSbj = {
                journeyId: sbj.journeyId,
                saves: []
            };
            for (const s of sbj.saves) {
                currIndex++;
                if (currIndex < startIndex) {
                    continue;
                }
                currSbj.saves.push(s);
                soFarCount++;
                if (soFarCount >= maxCount) {
                    break;
                }
            }
            if (currIndex < startIndex) {
                continue;
            }
            limitedSavesByJourney.push(currSbj);
            if (soFarCount >= maxCount) {
                break;
            }
        }
        return limitedSavesByJourney;
    };

    return (
        <Page
            id="load-page"
            headingText="Load Game"
            headingIconClass="fa-solid fa-upload"
            needsAuth={true}
            collapsePadding={true}
            onLoginConfirmed={onLoginConfirmed}
            isChildrenLoading={!savesForGame}
        >
            {savesForGame &&
                <div className="LoadPage">
                    {itemCount === 0 &&
                        <p>You have no saved games yet.</p>
                    }
                    {itemCount > itemsPerPage &&
                        <ReactPaginate
                            className="react-paginate"
                            breakLabel="…"
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            forcePage={currPage}
                        />
                    }
                    {
                        currentPageSbjs.map(sbj => (
                            <JourneyLoadBubble key={sbj.journeyId} savesForJouney={sbj} />
                        ))
                    }
                    {itemCount > itemsPerPage &&
                        <ReactPaginate
                            className="react-paginate"
                            breakLabel="…"
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            forcePage={currPage}
                        />
                    }
                </div>
            }
        </Page>
    );
}
