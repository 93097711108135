import "./NewableGame.css";
import ChatBubble from "components/Chat/ChatBubble/ChatBubble";
import ChatPreContainer from "components/Chat/ChatPreContainer/ChatPreContainer";
import ChatPre from "components/Chat/ChatPre/ChatPre";
import ChatText from "components/Chat/ChatText/ChatText";
import LazyPicture from "components/LazyPicture/LazyPicture";
import { ChatViewType } from "components/Chat/ChatViewType";
import { LazyPicSize } from "components/LazyPicture/LazyPicSize";
import { JourneyInitializer } from "JourneyInitializer";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthError } from "auth/AuthError";
import { Fragment, useState } from "react";
import { useSaveCheckStore } from "components/SaveCheckModal/SaveCheckStore";
import NewGameButton, { NewGameButtonStates } from "../NewGameButton/NewGameButton";
import { usePlaySetupStore } from "components/Chat/PlaySetupStore";
import MailingListSubscribe from "components/MailingListSubscribe/MailingListSubscribe";
import { SubscriptionSource } from "backend-models/services/mailing-list/mailing-list-api-models/SubscriptionSource";
import StatelessButton from "components/forms/StatelessButton/StatelessButton";

interface IProps {
    gameId: string;
    text: string;
    imgUrl: string;
    isSubscribe?: boolean;
}

export default function NewableGame(props: IProps) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const { performSaveCheck } = useSaveCheckStore();
    const [createGameButtonState, setCreateGameButtonState] = useState(NewGameButtonStates.Ready);
    const { clearPreservedActionText } = usePlaySetupStore();

    const createNewGame = async () => {
        if (props.isSubscribe) {
            return;
        }
        performSaveCheck(

            // Proceed
            async () => {
                try {
                    setCreateGameButtonState(_ => NewGameButtonStates.Creating);
                    clearPreservedActionText();

                    if (isAuthenticated) {
                        await JourneyInitializer.clearAutosaveIfExists(props.gameId);
                    }

                    // Refresh rather than navigate, to guarantee that the journey
                    // will be re-initialized
                    setCreateGameButtonState(_ => NewGameButtonStates.Ready);
                    window.location.href = "/";
                } catch (e) {
                    setCreateGameButtonState(_ => NewGameButtonStates.Error);
                    if (e instanceof AuthError) {
                        loginWithRedirect(
                            { appState: { returnTo: window.location.pathname } });
                    } else {
                        console.error(e);
                    }
                }
            },

            // Cancel
            () => { console.log("Aborted creating new game"); },
        );
    };

    return (
        <div className={"NewableGame" + (props.isSubscribe ? " NewableGame-not-clickable" : "")}>
            <ChatBubble viewType={ChatViewType.NewGame} onBubbleClicked={createNewGame} >
                <div className="NewableGame-rows">
                    <div className="NewableGame-pic-row">
                        {!props.isSubscribe &&
                            <Fragment>
                                <LazyPicture
                                    imgUrlOverride={props.imgUrl}
                                    size={LazyPicSize.NewGameCover}
                                    removeBottomMargin={true}
                                />
                                <div className="NewableGame-overlay-box">
                                    <StatelessButton
                                        onPressed={createNewGame}
                                        text="Start New Game"
                                        tip="Click to Begin a New Adventure"
                                    />
                                </div>
                            </Fragment>
                        }
                        {props.isSubscribe &&
                            <MailingListSubscribe source={SubscriptionSource.NewGame} />
                        }
                    </div>
                    <div className="NewableGame-pre-row">
                        <ChatPreContainer>
                            <ChatPre viewType={ChatViewType.NewGame}>
                                <ChatText text={props.text} />
                            </ChatPre>
                        </ChatPreContainer>
                    </div>
                </div>
                <div className="NewableGame-tray">
                    <NewGameButton onPressed={createNewGame} buttonState={createGameButtonState} />
                </div>
            </ChatBubble>
        </div>
    );
};
