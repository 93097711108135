import { SubscriptionSource } from "backend-models/services/mailing-list/mailing-list-api-models/SubscriptionSource";
import "./PlayPage.css";
import ChatPanel from "components/ChatPanel/ChatPanel";
import MailingListSubscribe from "components/MailingListSubscribe/MailingListSubscribe";
import Page from "components/Page/Page";
import { WebConfig } from "WebConfig";
import { authStore } from "auth/AuthStore";
import { TurnPlayTokenCost } from "backend-models/app-api-models/validation/CommonSchemas";

export default function PlayPage() {
    const pageId = "play-page";
    const { userProfile } = authStore();
    const isStuckWithInsufficientTokens =
        WebConfig.IS_PAYMENT_DISABLED === "1"
        && ((!userProfile) || userProfile.tokenBalance < TurnPlayTokenCost);

    return (
        <Page id={pageId} collapsePadding={true} removeTopSpacer={true}>
            <ChatPanel pageId={pageId} />
            {(WebConfig.IS_OUT_OF_SERVICE === "1" || isStuckWithInsufficientTokens) &&
                <div className="PlayPage-disabled">
                    <p>We plan to open up more free trials soon! Subscribe to be the first to know.</p>
                    <MailingListSubscribe source={SubscriptionSource.PlayOutOfService} />
                </div>
            }
        </Page>
    );
}
