import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import dayjs from "dayjs";
import { playStore } from "PlayStore";
import { ISavesByGameId } from "backend-models/services/saves/saves-api-models/ISavesByGameId";

export class SavesRetreiver {

    public static async getSavesForGame(): Promise<ISavesByGameId> {
        const accessToken = authStore.getState().accessToken;
        const game = playStore.getState().game;
        const gameId = (game?.builtInGameId || game?.id) as string;

        const response = await fetch(
            `/api/v1/user/saves?gameId=${gameId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when retrieving saved games.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when retrieving saved games.");
        }

        // Deserialize dayjs dates
        const model = await response.json() as ISavesByGameId;
        for (const sbj of model.savesByJourney) {
            for (const save of sbj.saves) {
                save.savedTimeUtc = dayjs(save.savedTimeUtc);
            }
        }

        return model;
    }

}
