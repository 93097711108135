import { ReactNode } from "react";
import "./ChatPreContainer.css";

interface IProps {
    children: ReactNode;
}

export default function ChatPreContainer(props: IProps) {
    return (
        <div className="ChatPreContainer">
            {props.children}
        </div>
    );
}
