import { ErrorCopy } from "copy/ErrorCopy";
import { SubmitValidationError } from "../SubmitValidationError";
import { IContactTicket } from "backend-models/services/contact/contact-api-models/IContactTicket";
import { IContactUsRequest } from "backend-models/app-api-models/IContactUsRequest";
import { authStore } from "auth/AuthStore";
import { contactFormStore } from "./ContactFormStore";

export class ContactTicketSubmitter {

    public static async submit(captchaToken: string, ticket: IContactTicket) {
        const accessToken = authStore.getState().accessToken;

        const requestBody: IContactUsRequest = {
            captchaToken,
            ticket,
        };

        if (!accessToken) {
            const response = await this.getUnauthedResponse(requestBody);
            this.processResponse(response);
            return;
        }

        const response = await this.getResponse(accessToken, requestBody);
        if (response.status !== 401) {
            this.processResponse(response);
        } else {
            console.warn(ErrorCopy.SubmitContactUnauthed);
            const response = await this.getUnauthedResponse(requestBody);
            this.processResponse(response);
        }
    }

    private static async getResponse(accessToken: string, requestBody: IContactUsRequest) {
        return await fetch("/api/v1/contact-us", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(requestBody)
        });
    }

    private static async getUnauthedResponse(requestBody: IContactUsRequest) {
        return await fetch("/api/v1/contact-us-unauthed", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody)
        });
    }

    private static processResponse(response: Response) {
        if (response.status.toString().startsWith("4")) {
            console.error(ErrorCopy.FormValidationError);
            throw new SubmitValidationError(ErrorCopy.FormValidationError);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error(ErrorCopy.SubmitContactTicketError);
        }
    }

    /**
     * Example usage:
     *
     * import { useNavigate } from "react-router-dom";
     * const navigate = useNavigate();
     * <button
     *     onClick={() => {
     *         ContactTicketSubmitter.navigateToContactWithPrefill(
     *             "About something"
     *             "abc\n123",
     *             () => { navigate("/contact?purpose=Report%20Content"); },
     *         );
     *     }}
     * />
     *
     * @param subjectPrefill Initial text to display in the Subject on the contact form
     * @param messagePrefill Initial text to display in the Message on the contact form
     * @param navigateToContactFormCallback a callback function, in which you must navigate immediately to the contact form.
     */
    public static navigateToContactWithPrefill(
        subjectPrefill: string | null,
        messagePrefill: string | null,
        navigateToContactFormCallback: () => void
    ) {
        contactFormStore.getState().setSubjectPrefill(subjectPrefill);
        contactFormStore.getState().setMessagePrefill(messagePrefill);
        navigateToContactFormCallback();
    }
}
