import { ReactNode } from "react";
import "./ChatPre.css";
import { ChatViewType } from "components/Chat/ChatViewType";

interface IProps {
  children: ReactNode;
  viewType: ChatViewType | "input";
}

export default function ChatPre(props: IProps) {

  const chatPreClassNames = `ChatPre ChatPre-${props.viewType}`;

  return (
    <pre className={chatPreClassNames}>
      {props.children}
    </pre>
  );
}

