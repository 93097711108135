import { BuiltInGameId } from "backend-models/services/built-in-games/built-in-games-api-models/BuiltInGameId";
import { IJourneyView } from "backend-models/services/journies/journies-api-models/IJourneyView";
import { authStore } from "auth/AuthStore";
import { AuthError } from "auth/AuthError";
import { WebConfig } from "WebConfig";

export class JourneyInitializer {

    public static async initByGameId(gameId: string): Promise<IJourneyView> {
        if (WebConfig.IS_OUT_OF_SERVICE === "1") {
            return await this.getNewJourney(gameId);
        }
        const autoSavedJourney = await this.loadAutosavedJourneyIfExists(gameId);
        if (autoSavedJourney) {
            return autoSavedJourney;
        } else {
            return await this.getNewJourney(gameId);
        }
    }

    public static async getNewJourney(gameId: string): Promise<IJourneyView> {
        await Promise.resolve();
        if (gameId === BuiltInGameId.Mithgard35) {
            const newGameJourney: IJourneyView = {
                game: { builtInGameId: BuiltInGameId.Mithgard35, id: BuiltInGameId.Mithgard35 },
                history: []
            };
            return newGameJourney;
        }
        throw Error("Unsupported gameId");
    }

    public static async loadJourney(turnId: string): Promise<IJourneyView> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(
            `/api/v1/user/saves/${turnId}/load`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading a saved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading a saved game.");
        }

        const model = await response.json() as IJourneyView;
        return model;
    }

    public static async loadAutosavedJourneyIfExists(gameId: string): Promise<IJourneyView | null> {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(
            `/api/v1/user/autosaves/${gameId}/load`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading autosaved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (response.status === 404) {
            console.log("No Autosave yet available for this game");
            return null;
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading autosaved game.");
        }

        const model = await response.json() as IJourneyView;
        return model;
    }

    public static async clearAutosaveIfExists(gameId: string) {
        const accessToken = authStore.getState().accessToken;

        const response = await fetch(
            `/api/v1/user/autosaves/${gameId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });

        if (response.status === 401) {
            const noAuthMsg = "Unauthorized when loading autosaved game.";
            console.warn(noAuthMsg);
            throw new AuthError(noAuthMsg);
        } else if (!response.status.toString().startsWith("2")) {
            console.error({ response });
            throw Error("Error when loading autosaved game.");
        }

        console.log("Cleared autosave.");
    }
}
