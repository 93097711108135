import { Fragment } from "react";

export default function TermsOfUse() {

    return (
        <Fragment>
<p><strong>1. Description of the Service</strong></p>
<p>EndlessGPT™ is an AI-driven, text-based adventure game that generates
unique narratives and 8-bit style pixelated images based on user inputs.
The game is available worldwide and is intended for mature audiences.
Please note that the Service is in the early stages of development and
may experience interruptions, bugs, or changes in features.</p>
<p><strong>2. Eligibility</strong></p>
<ul>
<li><p><strong>Age Requirement:</strong> Minors are not permitted to
create or hold an account.</p></li>
<li><p><strong>Minors:</strong> Minors may use the Service only under
the supervision of a parent or legal guardian who agrees to be bound by
these Terms. The parent or legal guardian accepts sole responsibility
for the experience of persons under their supervision.</p></li>
<li><p><strong>Legal Capacity:</strong> By using the Service, you
represent and warrant that you have the legal capacity to enter into
this agreement.</p></li>
</ul>
<p><strong>3. Account Registration and Security</strong></p>
<ul>
<li><p><strong>Account Security:</strong> You are responsible for
maintaining the confidentiality of your login credentials and for all
activities that occur under your account.</p></li>
<li><p><strong>Notification of Breach:</strong> You agree to notify us
immediately of any unauthorized use of your account or any other
security breach.</p></li>
</ul>
<p><strong>4. User Conduct</strong></p>
<ul>
<li><p><strong>Prohibited Activities:</strong> You agree not to:</p>
<ul>
<li><p>Submit any content that is illegal, harmful, threatening,
defamatory, obscene, infringing, or otherwise objectionable.</p></li>
<li><p>Include any private, sensitive, or personally identifiable
information about yourself or others in your game actions.</p></li>
<li><p>Attempt to circumvent or disable any content filters or security
measures implemented in the Service.</p></li>
<li><p>Engage in activities that trigger our content blockers, such as
generating explicit, ultraviolent, or illegal content.</p></li>
<li><p>Use the Service for any commercial purposes without our express
written consent.</p></li>
</ul></li>
<li><p><strong>Content Violations:</strong> Content violations will be
tracked against your account. Repeated violations may result in
additional constraints on your account or termination.</p></li>
<li><p><strong>Responsibility for Content:</strong> You are solely
responsible for the content you submit and any consequences arising from
your actions.</p></li>
</ul>
<p><strong>5. Content Filters and Enforcement</strong></p>
<p>We employ content filters to prevent the generation of explicit,
ultraviolent, or illegal content. If you trigger content filters too
frequently, we may impose additional constraints on your account, such
as:</p>
<ul>
<li><p><strong>Action Screening:</strong> Restricting your possible
actions through pre-filtering.</p></li>
<li><p><strong>Account Suspension or Termination:</strong> Temporary or
permanent removal of access to the Service.</p></li>
<li><p><strong>No Refunds:</strong> No refunds for unused tokens will be
issued in such cases.</p></li>
</ul>
<p><strong>6. User-Generated Content (UGC)</strong></p>
<ul>
<li><p><strong>License Grant:</strong> By submitting content, you grant
EndlessGPT™ a non-exclusive, royalty-free, perpetual, worldwide license
to use, reproduce, modify, adapt, publish, translate, create derivative
works from, distribute, and display such content in connection with the
Service and our business, including for AI training, product
improvement, diagnostics, and marketing.</p></li>
<li><p><strong>No Ownership Rights:</strong> You acknowledge that you do
not own any gameplay content generated on EndlessGPT™ or submitted by
you.</p></li>
<li><p><strong>Public Sharing:</strong> If you use the sharing feature,
you consent to your gameplay being shared publicly. EndlessGPT™ has no
control over where the share access details may end up and is not
obligated to reverse any such sharing.</p></li>
<li><p><strong>No Liability:</strong> If explicit, ultraviolent, or
illegal content is generated unintentionally, then you are not permitted
so share it, and EndlessGPT™ accepts no liability for any decision by you
to share that content publicly in contravention of applicable laws or
ethics.</p></li>
</ul>
<p><strong>7. Virtual Tokens and Payments</strong></p>
<ul>
<li><p><strong>Nature of Tokens:</strong> Users may purchase virtual
tokens to play the game. These payments are considered donations to
support the development of the Service.</p></li>
<li><p><strong>No Guarantee of Service:</strong> We do not guarantee the
availability, continuity, or performance of the Service, nor do we
guarantee that you will be able to use any or all of your tokens to play
the game.</p></li>
<li><p><strong>No Refund Policy:</strong> No refunds will be issued for
unused tokens, including in cases where your account is suspended or
terminated due to violation of these Terms.</p></li>
<li><p><strong>Payment Processing:</strong> Payments are processed by
our third-party payment processor, <strong>Stripe</strong>. By making a
purchase, you agree to comply with Stripe's terms and policies.</p></li>
</ul>
<p><strong>8. Intellectual Property Rights</strong></p>
<ul>
<li><p><strong>Company Ownership:</strong> All content and materials
available on the Service, including but not limited to software,
graphics, text, images, and AI-generated content, are the property of
EndlessGPT™ and are protected by intellectual property laws.</p></li>
<li><p><strong>Limited License:</strong> We grant you a limited,
non-exclusive, non-transferable license to access and use the Service
for personal, non-commercial purposes.</p></li>
<li><p><strong>Restrictions:</strong> You agree not to copy, modify,
distribute, sell, or lease any part of the Service or included software,
nor may you reverse engineer or attempt to extract the source code of
that software.</p></li>
</ul>
<p><strong>9. Sharing Features</strong></p>
<p>By using the sharing feature, you consent to your gameplay being
shared publicly. Since EndlessGPT™ has no control over where the share
access details end up, you acknowledge that your shared content may
become widely accessible. You are responsible for ensuring that the
content you share complies with these Terms and all applicable laws.</p>
<p><strong>10. Disclaimers and Limitation of Liability</strong></p>
<ul>
<li><p><strong>"As Is" Provision:</strong> The Service is provided on an
"as is" and "as available" basis without warranties of any
kind.</p></li>
<li><p><strong>No Warranty:</strong> EndlessGPT™ disclaims all
warranties, express or implied, including, but not limited to, implied
warranties of merchantability, fitness for a particular purpose, and
non-infringement.</p></li>
<li><p><strong>Limitation of Liability:</strong> In no event shall
EndlessGPT™, its directors, employees, or agents, be liable for any
indirect, incidental, special, consequential, or punitive damages,
including loss of profits, data, use, goodwill, or other intangible
losses resulting from:</p>
<ul>
<li><p>Your access to or use of or inability to access or use the
Service.</p></li>
<li><p>Any content obtained from the Service.</p></li>
<li><p>Unauthorized access, use, or alteration of your transmissions or
content.</p></li>
</ul></li>
<li><p><strong>No Guarantee of Content Accuracy:</strong> AI-generated
content may not always be accurate, appropriate, or reliable. Use the
Service at your own risk.</p></li>
</ul>
<p><strong>11. Indemnification</strong></p>
<p>You agree to defend, indemnify, and hold harmless EndlessGPT™ and its
affiliates, officers, agents, employees, and partners from and against
any claims, liabilities, damages, losses, and expenses, including
without limitation reasonable legal and accounting fees, arising out of
or in any way connected with:</p>
<ul>
<li><p>Your access to or use of the Service.</p></li>
<li><p>Your violation of these Terms.</p></li>
<li><p>Your violation of any third-party rights, including any
intellectual property, privacy, or proprietary right.</p></li>
<li><p>Your decision to share content generated by the Service.</p></li>
</ul>
<p><strong>12. Termination</strong></p>
<ul>
<li><p><strong>Termination by Company:</strong> We reserve the right to
suspend or terminate your account or access to the Service at our sole
discretion, without prior notice, for any reason, including but not
limited to a breach of these Terms.</p></li>
<li><p><strong>Termination by User:</strong> You may terminate your
account at any time by discontinuing use of the Service.</p></li>
<li><p><strong>Effect of Termination:</strong> Upon termination, your
right to access and use the Service will immediately cease. All
provisions of the Terms which by their nature should survive termination
shall survive, including ownership provisions, warranty disclaimers,
indemnity, and limitations of liability.</p></li>
<li><p><strong>No Refunds:</strong> No refunds for unused tokens will be
issued upon termination of your account.</p></li>
</ul>
<p><strong>13. Privacy Policy</strong></p>
<p>Your use of the Service is subject to our Privacy Policy, which is
incorporated into these Terms by reference. Please review our Privacy
Policy to understand our practices.</p>
<p><strong>14. Changes to the Terms</strong></p>
<p>We reserve the right to modify or replace these Terms at any time. If
a revision is material, we will provide at least 15 days' notice prior
to any new terms taking effect, typically via email to the address
associated with your account or by posting a notice on our website.
Continued use of the Service after the changes take effect signifies
your acceptance of the new Terms.</p>
<p><strong>15. Governing Law and Jurisdiction</strong></p>
<p>These Terms are governed by and construed in accordance with the laws
of New Zealand, without regard to its conflict of law provisions. You
agree to submit to the exclusive jurisdiction of the courts located
within New Zealand to resolve any legal matter arising from these Terms
or your use of the Service.</p>
<p><strong>16. Third-Party Links</strong></p>
<p>Our Service may contain links to third-party websites or services
that are not owned or controlled by EndlessGPT™. We have no control over,
and assume no responsibility for, the content, privacy policies, or
practices of any third-party websites or services.</p>
<p><strong>17. Miscellaneous Provisions</strong></p>
<ul>
<li><p><strong>Entire Agreement:</strong> These Terms and our Privacy
Policy constitute the entire agreement between you and EndlessGPT™
regarding the Service.</p></li>
<li><p><strong>Severability:</strong> If any provision of these Terms is
found to be invalid or unenforceable, the remaining provisions will
remain in full force and effect.</p></li>
<li><p><strong>Waiver:</strong> Our failure to enforce any right or
provision of these Terms will not be considered a waiver of those
rights.</p></li>
<li><p><strong>Assignment:</strong> You may not assign or transfer these
Terms, or any rights or licenses granted hereunder. We may assign these
Terms without restriction.</p></li>
<li><p><strong>Force Majeure:</strong> EndlessGPT™ shall not be liable
for any failure or delay in performance due to causes beyond our
reasonable control, including but not limited to acts of God, war,
terrorism, riots, embargoes, acts of civil or military authorities,
fire, floods, accidents, or strikes.</p></li>
</ul>
<p><strong>18. User Feedback</strong></p>
<ul>
<li><p><strong>Feedback License:</strong> Any feedback, comments, or
suggestions you may provide regarding EndlessGPT™ or the Service is
entirely voluntary. We are free to use such feedback as we see fit and
without any obligation to you.</p></li>
<li><p><strong>Non-Confidentiality:</strong> Feedback is not considered
confidential information.</p></li>
</ul>
<p><strong>19. No Agency Relationship</strong></p>
<p>Nothing in these Terms shall be construed to create any partnership,
joint venture, employer-employee, agency, or franchisor-franchisee
relationship between you and EndlessGPT™.</p>
<p><strong>20. User Responsibility</strong></p>
<ul>
<li><p><strong>Compliance with Laws:</strong> You agree to comply with
all applicable laws and regulations when using the Service.</p></li>
<li><p><strong>Account Responsibility:</strong> You are responsible for
all activities that occur under your account, whether or not you know
about them.</p></li>
<li><p><strong>Protecting Login Credentials:</strong> You must keep your
login credentials secure and notify us immediately of any unauthorized
use.</p></li>
</ul>
        </Fragment>
    );
};
